.PromyApp {
  padding-top: 40px;
  text-align: center;
  background-color: #f2efef;
  min-height: 100vh;
}
.PromyApp .title {
  margin-top: 22px;
  font-size: 22px;
  font-weight: 400;
  color: #666;
}
.PromyApp .btn {
  margin-top: 22px;
  font-weight: 400;
  width: 80vw;
  max-width: 630px;
  height: calc(80vw / 48 * 2);
  min-height: 44px;
  font-size: 18px;
}
.PromyApp .continue {
  color: #999;
  margin-top: 15px;
  font-size: 17px;
}
