.CustomerWithdrawpage .font18{
    font-size: 18px;
}
.CustomerWithdrawpage .pl0{
    padding-left: 0;
}
.CustomerWithdrawpage .spacebeween{
    justify-content: space-between;
    flex-wrap: wrap;
}
.CustomerWithdrawpage .maginb0{
    margin-bottom: 4px;
}
.CustomerWithdrawpage .margint19{
    margin-top: 19px;
    margin-bottom: 4px;
}