.withdrawpage .tip {
  color: #ff1733;
  font-size: 12px;
  position: relative;
  padding-left: 24px;
}
.withdrawpage .tip::after {
  background: url("../../imgs/sprite.png")
    0 0 no-repeat;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 20px;
}
.withdrawpage .tit{
  height: 14px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 5px;
}
.withdrawpage .tit::after{
  border-bottom: 1px solid #eee;
  content: "";
  left: 60px;
  position: absolute;
  top: 8px;
  width: 100%;
}
.withdrawpage .faqlist .txt-list{
  font-size: 12px;
  margin-top: 10px;
}
.withdrawpage .faqlist .clearfix {
  display: block;
}
.withdrawpage .faqlist .clearfix::before{
  content: "";
  display: table;
}
.withdrawpage .faqlist .clearfix::after{
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}
.withdrawpage .faqlist .more{
  margin-top: 20px;
}
.withdrawpage .faqlist .txt-list .q {
  font-weight: 700;
  margin-top: 20px;
}
.withdrawpage .faqlist .txt-list .a {
  margin-top: 15px;
}
.withdrawpage .faqlist .txt-list .q .ico {
  color: #1268bb;
}
.withdrawpage .alignitems{
  align-items: center;
}
.withdrawpage .spacebeweent{
  justify-content: space-between;
}