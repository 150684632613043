.apppage{
    background-color: #FFFFFF;
}
.apppage .imagebox{
    background-color: #f2f2f2;
    padding: 50px 0 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.apppage .imagebox .left h1{
    font-weight: 350 !important;
    font-size: 50px;
    letter-spacing: 3px;
}
.apppage .imagebox .left p{
    font-weight: 350 !important;
    font-size: 22px;
    margin-bottom: 50px;
}
.apppage .imagebox .left .iosapp{
  border-radius: 20px;
  overflow: hidden;
  background-color: #FFFFFF;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.apppage .imagebox .left .iosapp .iostext{
  background-color: #f7f8f8;
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 0 10px 0;
  text-align: center;
  font-size: 30px;
  margin-bottom: 0;
}
.apppage .imagebox .right{
    width: 500px;
    border-radius: 20px;
    overflow: hidden;
}
.apppage .imagebox .right img{
    width: 100%;
}
.apppage .w1200{
    width: 1200px;
}
.apppage .bottom ,.apppage .bottom .imagebox{
   background-color: #11ba66;
   padding: 15px 0 15px 0;
}
.apppage .bottom .flex{
    display: flex !important;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}
.apppage .bottom .iostext{
    padding: 0 !important;
}
.apppage .bottom  .white{
    color: white;
}