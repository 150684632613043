body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: PingFang SC, Hiragino Sans GB, Helvetica Neue, Helvetica, Microsoft YaHei, 微软雅黑, Arial, sans-serif
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body{width: 100% !important;overflow-y: initial !important;}

body{background-color: #f9f9f9;}

iframe{display: none !important;}

.tsmodal .anticon-info-circle{display: none;}
.ant-table .ant-table-cell::before{display: none;}
.ant-table-wrapper .ant-table-thead >tr>th{background: transparent;}
.ant-form-item .ant-form-item-explain-error{font-size: 12px;}
.ant-form-item{margin-bottom: 21px;}

.ant-input.bgf5:hover,.ant-input.bgf5:focus{background-color: #f5f5f5 !important;}

.huise{
  fill: #999;
  /* filter: grayscale(100%); */
  filter: grayscale(100%);-webkit-filter: grayscale(100%);   
  -moz-filter: grayscale(100%);    
 -ms-filter: grayscale(100%);   
  -o-filter: grayscale(100%);
}


.w1200{width: 1200px;margin: 0 auto;}
.flex{display: flex;}
.flexCenter{display: flex;align-items: center;}
.flexAllCenter{display: flex;align-items: center;justify-content: center;}
.flwp{display: flex;flex-wrap: wrap;}
.flexColumn{display: flex;flex-direction: column;}
.rowFlex{flex: 1;overflow: hidden;}
.flex1{flex: 1;}
.item32{width: 32%;margin-right: 2%;}
.item49{width: 49%;margin-right: 2%;}
.item32:nth-child(3n),.item49:nth-child(2n){margin-right: 0;}
.row10{width: 100%;}
.row_5{width: 5%;}
.row1{width: 10%;}
.row15{width: 15%;}
.row2{width: 20%;}
.row3{width: 30%;}
.marginlauto{margin-left: auto !important;}
.marginrauto{margin-right: auto;}
.center{text-align: center;}
._left{text-align: left;}
.marginl10{margin-left: 10px;}
.marginr10{margin-right: 10px;}
.margint10{margin-top: 10px;}
.margint20{margin-top: 20px;}
.marginb20{margin-bottom: 20px;}
.marginl20{margin-left: 20px;}
.marginl4{margin-left: 4px;}
.marginr4{margin-right: 4px;}
.margint4{margin-top: 4px;}
.paddb12{padding-bottom: 12px;}
.paddt12{padding-top: 12px;}
.paddl12{padding-left: 12px;}
.paddb20{padding-bottom: 20px;}
.marginl2{margin-left: 2px;}
.paddh10{padding-inline: 10px;}
.paddv10{padding-block: 10px;}

.cursor{cursor: pointer;}
.link{text-decoration: underline;}

.pubh1{font-size: 40px;font-weight: 500;}
.font10{font-size: 10px !important;}
.font12{font-size: 12px !important;}
.font14{font-size: 14px !important;}
.font16{font-size: 16px !important;}
.font18{font-size: 18px !important;}
.font24{font-size: 24px !important;}

.weight600{font-weight: 600;}

.color3{color: #333;}
.color6{color: #666;}
.color9{color: #999;}
.colorca{color: #cacaca;}
.colord5{color: #d5d5d5;}
.colorlv{color: #11ba66 !important;}
.colorzi{color: #5c4e8e;}
.coloryellow{color: #ffc001;}
.colorjh{color: #edb547;}
.colorju{color: #f70;}

.bgbai{background-color: #fff;}
.bgf2{background-color: #f2f2f2;}
.bgf5{background-color: #f5f5f5;}
.bglv{background-color: #11ba66 !important;}
.bgzi{background-color: #5c4e8e;}
.bgyellow{background-color: #ffc001 !important;}
.bgc{background-color: #ccc !important;}

.border0{border: 0;}
.borderlv{border-color: #11ba66 !important;}
.borderb1f2{border-bottom: 1px solid #d4d1d1;}
.bordert1f2{border-top: 1px solid #d4d1d1;}
.borderb1f5{border-bottom: 1px solid #f5f5f5;}
.borderf2{border: 1px solid #d4d1d1;}

.line1{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.line2{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}

.width12{width: 12px;}
.width16{width: 16px;}
.width80{width: 80px;}
.height80{height: 80px;}
.gqicon{width: 18px;height: 14px;}

.h28{height: 28px !important;}

.block{display: block;}

.pubbtn p{margin-right: 10px;cursor: pointer;}
.pubbtn p:last-child{margin-right: 0;}

.selectcountrybox .ant-select .ant-select-selector{padding-left: 0;}

.empty .pic{display: block;width: 300px;margin: 50px auto 30px;}
.empty .flex{padding-top: 20px;border-top: 1px solid #d4d1d1;margin-top: 120px;}
.empty .rowFlex{margin-right: 120px;}
.empty .rowFlex img{display: block;width: 100%;margin-top: 20px;}
.empty .rowFlex:last-child{margin-right: 0;}

.goods_table .width80{display: block;border-radius: 5px;}
.goods_table .title:hover{color: #11ba66;}

.small_nav{line-height: 40px;height: 40px;}
.small_nav .content_right{margin-left: auto;}
.small_nav .content_right p{margin-right: 40px;cursor: pointer;}
.small_nav .content_right p:hover{color: #11ba66;}
.small_nav .content_right .lang{cursor: pointer;}
.small_nav .content_right .lang:hover{color: #11ba66;}
.small_nav .lang .icon-a-bianzu4{margin: 0 4px;}

.langWrap .ant-popover-inner{padding: 20px;}
.langbox{width: 200px;}
/* .langbox .title{font-size: 16px;} */
.langbox .flwp p{height: 24px;font-size: 12px;text-align: center;border-radius: 3px;border: 1px solid #dcdfe6;margin-top: 10px;line-height: 24px;cursor: pointer;}
.langbox .flwp p.active{color: #11ba66;border-color: #11ba66;background-color: rgba(17,186,102,.06);}


.navBar{box-shadow: 0 6px 3px -2px rgba(0,0,1,.04);}
.navBar .logo{display: block;width: 120px;margin-right: 40px;}
.navBar .ant-menu.ant-menu-horizontal .ant-menu-submenu-arrow{display: inline-block;position: initial;margin-left: 4px;margin-bottom: 4px;transform: rotate(90deg);}
.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub{min-width: initial;}

.navBar .searchBox{border-radius: 4px;}
.navBar .searchBox input{width: 270px;}
.navBar .searchBox .upbtn{width: 40px;height: 40px;color: #919191;}
.navBar .icon-yunfeigusuan{font-size: 40px;}
.navBar .yfgs{font-size: 12px;}
.navBar .avatar{display: block;width: 40px;height: 40px;border-radius: 100%;cursor: pointer;}

.right_menus{width: 40px;}
.right_menus .menus{padding: 5px 0;box-shadow: 0 2px 6px 0 rgba(0,0,0,.1);border-radius: 2px;background-color: #fff;}
.right_menus .menus div{width: 100%;height: 40px;text-align: center;cursor: pointer;}
.right_menus .menus i{font-size: 30px;line-height: 40px;}
.right_menus .totop{width: 40px;height: 40px;border-radius: 2px;background-color: #fff;margin-top: 10px;box-shadow: 0 0 6px rgba(0,0,0,.12);}
.right_menus .totop::before,.right_menus .sj::before{display: block;content: '';width: 0;height: 0;border-left: 6px solid transparent;border-right: 6px solid transparent; border-bottom: 8px solid #333;}
.right_menus .sj::before{margin-left: 3px;}
.right_menus .ant-float-btn{position: initial;margin-top: 10px;border-radius: 2px;}
.right_menus .ant-float-btn-default .ant-float-btn-body:hover{background-color: initial;}

.shopping_car{width: 250px;}
.shopping_car .pic{display: block;width: 66px;height: 66px;}
.shopping_car .goods_num{padding-inline: 10px;margin: 2px 0;}
.shopping_car .goods_num img{display: block;width: 12px;}
.shopping_car .goods_num .ant-input{height: 20px;}
.shopping_car .list{line-height: 1.5;max-height: 480px;overflow-y: auto;padding-right: 15px;margin-left: -12px;}
.shopping_car .list>div{padding: 10px 12px;margin: 0 -12px 0 0;}
.shopping_car .list .iconfont{display: none;}
.shopping_car .list>.flex:hover{background-color: #f9f9f9;}
.shopping_car .list>.flex:hover .iconfont{display: block;}

.searchBanner{height: 440px;background-color: rgba(0, 0, 0, .6);background-image: url('./imgs/home_bg.png');background-size: cover;}
.searchBanner h1{font-size: 50px;font-weight: 300;padding: 85px 0 30px;text-align: center;color: #fff;}
.searchBanner .searchBox{height: 80px;width: 980px;margin: 0 auto 34px;border-radius: 6px;background-color: #fff;}
.searchBanner .searchBox .ant-select{width: 156px;}
.searchBanner .searchBox .ant-select-selector{text-align: center;font-size: 20px;}
.searchBanner .searchBox .ant-select-arrow{font-size: 18px;}
.searchBanner .searchBox .ant-input{font-size: 30px;font-weight: 300;}
.searchBanner .searchBox .upbtn{width: 50px;height: 50px;border-radius: 6px;border: 1px dashed #d9d9d9;}
.searchBanner .searchBox .icon-tupian{font-size: 32px;color: #c0c4cc;}
.searchBanner .searchBox .ant-btn{height: 80px;width: 200px;font-size: 32px;border-radius: 0 6px 6px 0;margin-left: 15px;}
.searchBanner .searchDesc{width: 1000px;margin: 0 auto;position: relative;}
.searchBanner .searchDesc .item{text-align: center;color: #fff;font-weight: 500;width: 240px;}
.searchBanner .searchDesc .item:nth-child(2){flex: 1;}
.searchBanner .searchDesc .item p:last-child{color: #8b8b8b;}
.searchBanner .searchDesc .item .round{width: 20px;height: 20px;border-radius: 100%;background-color: #ffffff52;margin: 0 auto 12px;overflow: hidden;}
.searchBanner .searchDesc .item .round::after{display: block;content: '';width: 10px;height: 10px;border-radius: 100%;background-color: #fff;margin: 5px 0 0 5px;}
.searchBanner .searchDesc::after{display: block;content: '';width: 755px;height: 0;border: 1px solid #fff;position: absolute;top: 9px;left: 120px;}

.banners{margin-top: 30px;margin-bottom: 20px;position: relative;}
/* .banners>div{position: relative;} */
.banners .jt{display: block;width: 40px;position: absolute;top: 50%;margin-top: -20px;cursor: pointer;z-index: 9;}
.banners .prev{left: 10px;transform: rotate(180deg);}
.banners .next{right: 40px;}
.banners .item{user-select: none;cursor: pointer;}
.banners .pic{display: block;width: 90%;}
.banners .slick-slide>div,.banners .slick-slide>div:focus-visible{outline: none;}
*:focus{outline: none;}

.serviceBox{padding: 20px;}
.serviceBox h1{font-size: 40px;text-align: center;font-weight: 500;padding: 36px 0;}
.serviceBox .list{justify-content: space-between;}
.serviceBox .list .item{margin-right: 15px;padding: 20px;border-radius: 8px;transition: all .3s ease-in;}
.serviceBox .list .item:last-child{margin-right: 0;}
.serviceBox .list .icon-box{width: 70px;}
.serviceBox .list .title{font-size: 18px;color: #333;}
.serviceBox .list .desc{font-size: 12px;color: #999;margin-top: 5px;}
.serviceBox .list .item:hover{box-shadow: 0 15px 40px 0 rgba(0,0,0,.1);}

.noticeBox{padding-bottom: 20px;}
.noticeBox h1{font-size: 40px;padding: 30px 0 0;text-align: center;font-weight: 500;}
.noticeBox .flexAllCenter{margin-bottom: 36px;}
.noticeBox .flexAllCenter .desc{color: #999;}
.noticeBox .flexAllCenter .link{color: #167bff;text-decoration: underline;cursor: pointer;margin-left: 8px;}
.noticeBox .list{display: flex;}
.noticeBox .list .item{margin-right: 16px;border-radius: 8px;box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);padding-bottom: 20px;}
.noticeBox .list .item:last-child{margin-right: 0;}
.noticeBox .list .item_top{padding: 30px 0 15px;}
.noticeBox .list .item .bgbai{width: 90px;height: 90px;border-radius: 100%;display: flex;align-items: center;justify-content: center;margin: 0 auto 10px;}
.noticeBox .list .item .iconfont{font-size: 40px;}
.noticeBox .list .item_top span{display: block;text-align: center;color: #fff;}
.noticeBox .list .msglist{padding: 10px 20px 0;}
.noticeBox .list .msg_item{padding: 10px 0;border-bottom: 1px solid #d4d1d1;cursor: pointer;}
.noticeBox .list .msg_item:last-child{border-bottom: 0;}
.noticeBox .list .msg_item .time{font-size: 12px;color: #999;}
.noticeBox .list .msg_item .cont{font-size: 14px;color: #333;}
.noticeBox .list .msg_item .cont:hover{text-decoration: underline;}

.tjbox{padding: 70px 0 0;}
.tjbox h1{padding-bottom: 36px;}
.tjbox .list .item{width: 220px;margin-right: 20px;margin-bottom: 20px;font-size: 12px;cursor: pointer;}
.tjbox .list .item .pic{display: block;width: 220px;height: 220px;}
.tjbox .list .item .tcont{margin-top: 10px;padding-left: 4px;}
.tjbox .list .item .tcont .icon{display: block;width: 16px;margin-right: 10px;}
.tjbox .list .item .pri{font-size: 16px;font-weight: 600;padding: 0 4px;}
.tjbox .list .item .user_cont{font-size: 12px;color: #999;padding: 0 4px 10px;}
.tjbox .list .item:hover{box-shadow: 0 0 15px rgba(0,0,0,.2);transform: scale(1.05);transition: transform .2s ease-in,-webkit-transform .2s ease-in;}

.iconsBox{padding-bottom: 20px;}
.iconsBox .flex{padding-top: 30px;padding-right: 20px;}
.iconsBox .iconfont{font-size: 40px;color: #8d8d8d;margin-right: 20px;line-height: normal;}
.iconsBox .title{font-size: 18px;}
.iconsBox .desc{font-size: 12px;color: #666;}

.footer{background-color: #282828;color: #999;padding-bottom: 45px;}
.footer .help{padding: 40px 0 15px;}
.footer .bot_menu .bt_item{width: 180px;}
.footer .bot_menu .bt_item h1{font-size: 15px;margin-bottom: 16px;}
.footer .bot_menu .bt_item p{font-size: 12px;margin-top: 6px;cursor: pointer;}
.footer .rightcontent{margin-left: auto;min-width: 60px;}
.footer .rightcontent h4{font-size: 15px;}
.footer .rightcontent .iconfont{font-size: 26px;cursor: pointer;}
.footer .copyright{padding-top: 20px;border-top: 1px solid #9c9ea6;color: #9c9ea6;}
.footer .copyright .right p{margin-left: 10px;cursor: pointer;}
.footer .footer_pay{display: block;height: 33px;margin-top: 30px;}

.help_top{padding: 60px 0 0;}
.help_top h1{font-size: 24px;margin-right: auto;}
.help_top .ant-tabs-tab{padding-top: 0px;font-size: 24px;color: #9c9ea6;font-weight: 500;}
.help_top .ant-tabs-top >.ant-tabs-nav::before{display: none;}
.help_top .ant-tabs-top .ant-tabs-ink-bar{height: 4px;bottom: 4px;}
.help_top .flexend .ant-tabs-nav-wrap{
  justify-content: end;
  min-width: max-content;
  max-width: 70vw;
}
.help_search{padding: 30px 0 80px;}
.help_search .inptBox{width: 700px;height: 55px;background-color: #fff;box-shadow: 0 8px 24px rgba(0,0,0,.2);margin: 0 auto;}
.help_search .inptBox .ant-input{flex: 1;font-size: 22px;}
.help_search .ant-btn{width: 100px;height: 55px;border-radius: 0;}
.help_search .ant-btn img{width: 30px;margin-top: 4px;}

.helpBox{padding: 20px 0;}
.helpBox h1{font-size: 30px;text-align: center;font-weight: 400;line-height: 60px;}
.helpBox .list .item{padding: 10px 0;border-bottom: 1px solid #ebeef5;cursor: pointer;}
.helpBox .list .item p{font-size: 13px;line-height: 48px;color: #303133;font-weight: 500;}
.helpBox .list .item .iconfont{transform: rotate(-90deg);font-size: 20px;font-weight: 200;}
.helpBox .ant-collapse .ant-collapse-header{padding-inline: 0;font-size: 13px;font-weight: 500;user-select: none;}
.helpBox .ant-collapse-item{padding: 10px 0;border-bottom: 1px solid #ebeef5;}
.helpBox .ant-collapse .ant-collapse-item .ant-collapse-content-box{padding-inline: 0;padding-top: 0;}

.quesBox{padding: 40px 0;}
.quesBox .left,.quesBox .right{padding: 0 15px;}
.quesBox .left{width: 25%;padding-bottom: 10px;}
.quesBox .left h1{font-size: 30px;padding-bottom: 20px;}
.quesBox .left .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline{background-color: transparent;}

.quesBox .right .top .home{display: block;width: 14px;}
.quesBox .right .top .iconfont{transform: rotate(-90deg);margin: 0 6px;}
.quesBox .right .top p{color: #606266;}
.quesBox .list{padding: 20px;}
.quesBox .list p{line-height: 28px;cursor: pointer;color: #333;}
.quesBox .list p:hover{color: #11ba66;}

.yfgs_banner{height: 335px;background-image: url('./imgs/customized_bg.png');background-position: 50%;background-size: cover;}
.yfgs_banner h1{color: #fff;text-align: center;padding-top: 40px;}
.yfgs_banner .detail{font-size: 16px;color: #fff;text-align: center;}
.yfgs_banner .ant-form{margin-top: 20px;padding: 20px 0;}
.yfgs_banner .ant-form .ant-form-item{background-color: #fff;padding-left: 10px;border-radius: 4px;}
/* .yfgs_banner .item49.flex{border-radius: 4px;overflow: hidden;} */
.yfgs_banner .item49.flex .ant-form-item{border-radius: 0;}
.yfgs_banner .item49.flex .ant-form-item:first-child{border-radius: 4px 0 0 4px;}
.yfgs_banner .item49.flex .ant-form-item:last-child{border-radius: 0 4px 4px 0;}
.yfgs_banner .item49.flex .ant-form-item:nth-child(2){border-left: 1px solid #999;border-right: 1px solid #999;}
.yfgs_banner .ant-form .ant-btn{margin-left: 20px;height: 88px;width: 156px;font-size: 18px;font-weight: 500;}


.query_state{padding: 100px 0 150px;}
.query_state .p1{font-size: 18px;color: #999;line-height: 24px;text-align: center;}
.query_state .ant-btn{display: block;margin: 10px auto 0;}

.package_list{padding: 20px 0;}
.package_list .btn_box{border-top: 1px solid #d4d1d1;padding: 20px 0;}
.package_list .sortbtn{color: #666;cursor: pointer;}
.package_list .sortbtn .iconfont{display: block;line-height: normal;}
.package_list .sortbtn .iconfont:first-child{margin-bottom: -12px;}
.package_list .sortbtn.active{color: #11ba66;}
.package_list .item{border: 1px solid #d4d1d1;}
.package_list .item.active,.package_list .item:hover{background-color: #f8f8f8;}
.package_list .item:nth-child(n+2){border-top: 0;}
.package_list .item .top{padding: 20px 0;cursor: pointer;margin-bottom: 20px;}
.package_list .item .title{text-align: center;width: 12.5%;margin-right: 2.5%;}
.package_list .item .fee_box{width: 20%;margin-right: 2.5%;}
.package_list .item .time_box{width: 14%;margin-right: 2.5%;}
.package_list .item .btn{width: 15%;}
.package_list .item .logo{position: relative;width: 66px;margin: 0 auto;}
.package_list .item .logo img{display: block;width: 64px;height: 64px;border-radius: 100%;border: 1px solid #d4d1d1;}
.package_list .item .logo .dp,.line_content .item .logo .dp{display: block;width: 17px;height: 22px;background-image: url('./imgs/dp.png');background-size: 24px;background-position: 50%;position: absolute;right: 0;bottom: 0;cursor: pointer;}
.package_list .item .tags .ant-tag{padding-inline: 2px;line-height: 1;color: #11ba66;background-color: rgba(17,186,102,.15);}
.package_list .item .fee_box h1{font-size: 24px;}
.package_list .item .time_box .h1 span{font-size: 24px;font-weight: bold;}
.package_list .item .time_box .ant-progress{width: 100px;}
.package_list .item .time_box .ant-progress div{height: 6px !important;}
.package_list .item .details{padding: 30px 20px 10px;margin-top: -20px;}
.package_list .item .details .chart_box{padding: 5px 10px;}
.package_list .item .details .row p{margin: 5px;width: 60px;}
.package_list .item .details .row .ant-progress{margin-bottom: 0;}
.package_list .item .details .row span{width: 40px;}
.package_list .pdtips{padding-right: 20px;padding-bottom: 10px;text-align: right;position: absolute;right: 0;bottom: 0;}
.package_list .item .details .left{width: 54%;}
.package_list .item .tabbox{border: 1px solid #d4d1d1;}
.package_list .tabbox .weight div:first-child{border-right: 1px solid #d4d1d1;}
.package_list .tabbox .weight div,.package_list .tabbox .size,.package_list .tabbox .tjrules,.package_list .tabbox .types{padding: 10px 20px;}
.package_list .tabbox .lb span{display: flex;align-items: center;}
.package_list .tabbox .lb span::before{display: block;content: '';width: 12px;height: 12px;border-radius: 100%;margin-right: 4px;background-color: #ff4700;margin-top: -2px;}
.package_list .tabbox .lb span:last-child:before{background-color: #11ba66;}
.package_list .tabbox .cont{max-height: 400px;overflow-y: auto;}
.package_list .tabbox .cont .flwp{margin-top: 8px;}
.package_list .tabbox .cont .flwp p{padding: 2px 4px;margin-right: 10px;margin-bottom: 10px;border-radius: 2px;line-height: 18px;}
.package_list .tabbox .cont .flwp p.ky{color: #11ba66;background-color: rgba(17,186,102,.1);}
.package_list .tabbox .cont .flwp p.bky{color: #ff4700;background-color: rgba(255,0,0,.1);}
.package_list .jf_table{border: 1px solid #d4d1d1;}
.package_list .jf_table>.flex{border-top: 1px solid #d4d1d1;}
.package_list .jf_table>.flex>div{height: 50px;padding: 5px;border-right: 1px solid #d4d1d1;display: grid;align-items: center;}
.package_list .jf_table>.flex>div:last-child{border-right: 0;}
.package_list .jf_table>.flex:first-child{border-top: 0;}
.package_list .jf_table>.flex>div>p:first-child{text-decoration: line-through;}
.package_list .jf_table>.flex>div>p.font14{color: #ff4700;}

.custom_cn{display: block;width: 100%;}

.customBox .title{font-size: 24px;font-weight: 500;margin: 10px 0;line-height: 32px;}
.customBox .addbtn{height: 130px;background-image: url('./imgs/transport_addr.png');background-size: 100% 100%;margin-bottom: 20px;text-align: center;}
.customBox .empry-address{height: 100%;border: 1px dashed #666;background-color: hsla(0,0%,100%,.5);display: flex;flex-direction: column;justify-content: center;align-items: center;}
.customBox .addbtn .ant-btn{margin-top: 10px;}
.customBox .addbtn .addr_title{color: #333;}
.customBox .jia{display: block;width: 16px;height: 16px;cursor: pointer;}
.customBox .icon-tupian{font-size: 28px;line-height: normal;}
.customBox .ant-form{padding-top: 10px;}
.customBox .ant-form>.flex>.ant-form-item{margin-right: 10px;}
.customBox .ant-form>.flex>.btn{margin-left: 10px;}
.customBox .icon-dingdanhuishouzhan{font-size: 22px;cursor: pointer;line-height: normal;margin-top: 32px;}
.customBox .ant-form-item-control-input{background-color: #f5f5f5;}
.customBox .ant-input-prefix{color: #666;}
.customBox .add{height: 48px;font-size: 18px;color: #999;margin-bottom: 20px;margin-top: 20px;}
.customBox .flexAllCenter{padding-bottom: 30px;}
.customBox .flexAllCenter .ant-btn{height: 50px;width: 120px;font-size: 18px;}
.customBox .btn .ant-form-item-control-input{background: transparent;}
.customBox .rowFlex>.flex>.ant-form-item:nth-child(2){border-left: 2px solid hsla(0,0%,84.7%,.5);border-right: 2px solid hsla(0,0%,84.7%,.5);}
.customBox .rowFlex>.flex>.ant-form-item .ant-input{text-align: center;}

.addrbox .ant-form{margin-top: 30px;}
.addrbox .ant-btn{display: block;margin: 0 auto;width: 90px;height: 36px;}

.ntbanner{min-height: 280px;}
.nt_tabs{background-color: #e9ebeb;}
.nt_tabs .ant-tabs-top >.ant-tabs-nav::before{display: none;}
.nt_tabs .ant-tabs .ant-tabs-tab{font-size: 20px;font-weight: 500;}
.nt_tabs .ant-tabs .ant-tabs-ink-bar{height: 3px;bottom: 4px;}
.nt_tabs .ant-input{width: 200px;margin-left: auto;border-color: #dcdfe6;height: 40px;border-radius: 20px;font-size: 16px;}

.ntlist{padding: 30px 0;}
.ntlist .list{width: 72%;}
.ntlist .item{margin-bottom: 30px;cursor: pointer;align-items: flex-start;}
.ntlist .item .pic{display: block;width: 30%;}
.ntlist .right{padding: 15px;flex: 1;overflow: hidden;}
.ntlist .right h1{font-size: 22px;}
.ntlist .right .desc{color: #4f4f4f;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;margin-top: 10px;}
.ntlist .right .time{color: #4f4f4f;}
.ntlist .right .user{color: #9c9ea6;margin-left: auto;line-height: 36px;}
.ntlist .item:hover h1{color: #11ba66;}
.ntlist .tjlist{flex: 1;margin-left: 5%;border: 1px solid #e5e5e5;border-radius: 4px;margin-top: 30px;overflow: hidden;}
.ntlist .tjlist h1{padding: 0 10px;color: #1e222e;line-height: 48px;}
.ntlist .rlist .row{padding: 0 10px 10px;border-top: 1px solid #e5e5e5;cursor: pointer;}
.ntlist .rlist .row h3{line-height: 38px;margin-bottom: 10px;font-size: 16px;}
.ntlist .rlist .row .flexCenter p{color: #9c9ea6;}
.ntlist .rlist .row:hover h3{color: #11ba66;}

.detailbox{padding: 50px 0 100px;width: 72%;}
.detailbox .top{padding: 30px;}
.detailbox .top h1{font-size: 27px;}
.detailbox .top span{line-height: 40px;}
.detailbox .top span:first-child{margin-right: 30px;}
.detailbox .cont{margin-top: 30px;padding: 45px 90px;}
.detailbox .cont img{max-width: 100%;}

.goodsDetail{padding-bottom: 20px;}
.goodsDetail .pinfo{padding-top: 30px;}
.goodsDetail .pinfo .left{width: 420px;}
/* .goodsDetail .pinfo .pic_box{border: 1px solid #f6f4f7;} */
.goodsDetail .pinfo .pic_box img{display: block;width: 420px;}
.goodsDetail .pinfo .imglist{margin-top: 3px;}
.goodsDetail .pinfo .imglist div{margin-right: 5px;/*max-width: calc((100% - 12px) / 4);*/cursor: pointer;}
.goodsDetail .pinfo .imglist img{display: block;width: 100%;max-height: 80px;border: 1px solid #f6f4f7;box-sizing: border-box;}
.goodsDetail .pinfo .imglist div:last-child{margin-right: 0;}
.goodsDetail .pinfo .imglist .active img{opacity: .4;}
.goodsDetail .left .shareBox{padding-top: 30px;color: hsla(0,0%,60%,.8);}
.goodsDetail .left .shareBox .ant-btn>div{display: none;}
.goodsDetail .left .shareBox div{margin-left: 20px;cursor: pointer;}
.goodsDetail .left .shareBox div .iconfont{font-size: 22px;line-height: normal;margin-right: 2px;}
.goodsDetail .left .sameBox{margin-top: 20px;padding: 20px 0;border-top: 1px solid #d4d1d1;font-size: 12px;color: #999;}
.goodsDetail .left .sameBox span{color: #2e5aef;margin-left: 10px;cursor: pointer;}
.goodsDetail .right{padding-left: 20px;flex: 1;overflow: hidden;}
.goodsDetail .right .shopInfo{padding-bottom: 10px;margin-bottom: 10px;border-bottom: 1px solid #d4d1d1;}
.goodsDetail .right .shopInfo .tao{display: block;width: 16px;margin-left: 10px;}
.goodsDetail .right .shopInfo .name{min-width: 150px;margin-left: 4px;font-weight: 500;}
.goodsDetail .right .shopInfo .to{padding: 2px 10px;font-size: 12px;border-radius: 14px;color: #fff;background-color: #333;margin-left: 10px;cursor: pointer;}
.goodsDetail .right .shopInfo .to:hover{color: #11ba66;}
.goodsDetail .goods_title_left{align-items: baseline;flex: 1;overflow: hidden;}
.goodsDetail .goods_title_left .title{font-size: 14px;font-weight: bold;overflow: hidden;}
.goodsDetail .goods_title_left .rbtn{font-size: 12px;color: #2e5aef;margin-left: 10px;vertical-align: middle;cursor: pointer;}
.goodsDetail .goods_title_left .rbtn img{display: block;width: 12px;margin-right: 2px;margin-top: -3px;}
.goodsDetail .goods_title_right{margin-left: 30px;border-left: 1px solid #d4d1d1;width: 120px;text-align: center;}
.goodsDetail .goods_title_right .num{color: #666;}
.goodsDetail .goods_title_right .sales{color: #999;font-size: 12px;}
.goodsDetail .goods_title_right .sales .iconfont{margin-left: 4px;cursor: pointer;line-height: normal;font-size: 14px;}

.goodsDetail .pribox{align-items: baseline;}
.goodsDetail .pribox h1{font-size: 32px;}
.goodsDetail .pribox .edit{font-size: 12px;color: #11ba66;display: flex;align-items: center;margin-left: 15px;font-weight: 500;}
.goodsDetail .pribox .edit::before{display: block;content: '';width: 12px;height: 12px;background-image: url('./imgs/edit.png');background-size: 100% 100%;margin-right: 4px;}
.goodsDetail .pri_tips{font-size: 12px;color: #666;}

.goodsDetail .express{margin-top: 20px;padding-bottom: 14px;border-radius: 4px;overflow: hidden;line-height: 20px;color: #333;}
.goodsDetail .express .source{margin-top: 5px;padding: 5px 10px;border-radius: 0 6px 6px 0;background-color: #11ba66;color: #fff;font-size: 12px;font-weight: 400;}
.goodsDetail .express .step{margin-top: 12px;padding: 0 20px;}
.goodsDetail .express .step .num{width: 18px;height: 18px;border-radius: 100%;text-align: center;line-height: 18px;color: #fff;background-color: #11ba66;font-weight: 500;margin-right: 8px;}
.goodsDetail .express .step span{margin-right: 6px;}
.goodsDetail .express .step .sj{width: 0;height: 0;border-top: 4px solid transparent;border-bottom: 4px solid transparent;border-left: 6px solid #999;}
.goodsDetail .express .step div{color: #2e5aef;cursor: pointer;font-weight: 500;}
.goodsDetail .express .dline{width: 9px;height: 16px;border-right: 1px dotted #11ba66;margin-left: 20px;}
.goodsDetail .specbox{padding-top: 10px;line-height: 32px;}
.goodsDetail .specbox .item{padding-top: 5px;}
.goodsDetail .specbox .item h2{line-height: 22px;}
.goodsDetail .specbox .item .flwp{align-items: flex-start;margin-top: 10px;}
.goodsDetail .specbox .item .btn{margin-right: 10px;margin-bottom: 10px;color: #777;border: 2px solid #eee;cursor: pointer;}
.goodsDetail .specbox .item .btn.active{border-color: #11ba66;}
.goodsDetail .specbox .item .btn span{padding: 1px 11px;}
.goodsDetail .specbox .item .btn .pic{display: block;width: 40px;height: 40px;}
.goodsDetail .specbox .item .lybox{background-color: #f5f5f5;border-color: #f5f5f5;margin-top: 10px;box-shadow: none;}
.goodsDetail .specbox .item .lybox textarea{background-color: transparent;border: 0;}
.goodsDetail .specbox .desc{padding: 10px 0;color: #999;line-height: 21px;}
.goodsDetail .specbox .borderb1{border-bottom: 1px solid #d4d1d1;}
.goodsDetail .shopbtn{padding: 30px 0;border-bottom: 1px solid #d4d1d1;}
.goodsDetail .shopbtn .ant-btn{font-size: 18px;font-weight: bold;height: 60px;}
.goodsDetail .atips{font-size: 12px;color: #999;margin-top: 20px;}
.goodsDetail .atips span{color: #2e5aef;font-weight: 500;cursor: pointer;}
.goodsDetail .shopCont{position: relative;}
.goodsDetail .shopCont div.item{flex: 1;}
.goodsDetail .shopCont div.item .pic{border: 1px solid #d4d1d1;width: 90%;}
.goodsDetail .shopCont div.item img{display: block;width: 100%;}
.goodsDetail .shopCont div.item h4{margin-top: 10px;}
.goodsDetail .shopCont div.item p{font-size: 12px;}
.goodsDetail .shopCont .next_btn,.goodsDetail .shopCont .prev_btn{width: 30px;height: 30px;background-color: rgba(0,0,0,.3);cursor: pointer;background-image: url('./imgs/jt.png');background-size: 20px 20px;position: absolute;top: 50%;margin-top: -15px;background-repeat: no-repeat;background-position: 0 center;}
.goodsDetail .shopCont .next_btn{right: 22px;}
.goodsDetail .shopCont .prev_btn{left: 2px;transform: rotate(180deg);}
.goodsDetail .sjtabs .ant-tabs-tab{font-size: 16px;}
.goodsDetail .sjtabs .ant-tabs-tab.ant-tabs-tab-active{font-weight: bold;}
.goodsDetail .sjtabs .ant-tabs-ink-bar,.goodsDetail .infotabs .ant-tabs-ink-bar{height: 3px;}
.goodsDetail .infotabs{margin-top: 20px;}
.goodsDetail .infotabs .ant-tabs-nav{margin-bottom: 0;}
.goodsDetail .infotabs .ant-tabs-tab.ant-tabs-tab-active{font-weight: bold;}
.goodsDetail .details{border: 1px solid #d4d1d1;padding: 20px 0;}
.goodsDetail .details>div{margin: 0 auto;max-width: 790px;height: auto !important;}
.goodsDetail .details>div>div{max-width: 100%;height: auto !important;}
.goodsDetail .details>div img{max-width: 100%;}
.goodsDetail .reviews{margin-top: 30px;max-height: 500px;overflow-y: auto;}
.goodsDetail .reviews .item{padding-bottom: 20px;margin-bottom: 20px;border-bottom: 1px solid #d4d1d1;align-items: flex-start;}
.goodsDetail .reviews .item .ant-rate{color: #ff4700;font-size: 12px;}
.goodsDetail .reviews .lcont{margin-right: 10px;width: 17.5%;}
.goodsDetail .reviews .lcont .avatar{display: block;width: 40px;height: 40px;border-radius: 100%;}
.goodsDetail .reviews .lcont .name{font-size: 12px;margin-left: 10px;}
.goodsDetail .reviews .lcont .star{margin-top: 10px;font-size: 12px;}
.goodsDetail .reviews .lcont .star .p1{margin-right: 6px;}
.goodsDetail .reviews .star .ant-rate-star{margin-right: 3px;}
.goodsDetail .reviews .item .title{font-size: 12px;color: #999;}
.goodsDetail .reviews .item .content{font-size: 12px;margin-bottom: 10px;}
.goodsDetail .reviews .item .time{font-size: 12px;color: #999;margin: 4px 0 20px;}
.goodsDetail .params{padding: 20px 10px;color: #999;line-height: 40px;font-weight: 500;}
.goodsDetail .params .ant-row{border-bottom: 1px dashed #ccc;}
.goodsDetail .noticebox{padding: 20px 0;}
.goodsDetail .noticebox .title{font-size: 24px;}
.goodsDetail .noticebox .tips{font-size: 12px;color: #666;font-weight: 500;}
.goodsDetail .noticebox .desc{font-size: 12px;color: #999;}
.goodsDetail .noticebox .tips span{color: #2e5aef;cursor: pointer;}
.goodsDetail .noticebox .flwp{padding: 16px 0;}
.goodsDetail .noticebox img{display: block;width: 80px;height: 80px;margin-right: 20px;}
.goodsDetail .aftersalesBox{padding: 20px 20px 50px;}
.goodsDetail .aftersalesBox p{color: #666;line-height: 28px;}

.editPribox .inpt{background-color: #f5f5f5;height: 40px;font-size: 18px;color: #606266;box-shadow: none;margin-top: 10px;}
.editPribox .inpt .ant-input-prefix{width: 60px;color: #c0c4cc;}
.editPribox .warnbox{font-size: 12px;color: #f00;padding-top: 10px;padding-bottom: 24px;border-bottom: 1px solid #d4d1d1;}
.editPribox .warnbox img{display: block;width: 14px;margin-top: -1px;margin-right: 4px;}
.editPribox .title{margin-bottom: 10px;margin-top: 20px;}
.editPribox .flwp p{width: 32%;margin-right: 2%;margin-bottom: 10px;font-size: 12px;border: 1px solid #dcdfe6;padding: 6px 0;border-radius: 4px;text-align: center;cursor: pointer;font-weight: 500;color: #606266;}
.editPribox .flwp p:nth-child(3n){margin-right: 0;}
.editPribox .flwp p.active,.editPribox .flwp p:hover{border-color: #333;background-color: #333;color: #fff;}
.editPribox .btn{display: block;margin-left: auto;width: 80px;font-weight: 500;margin-top: 10px;}

.timeInfo{padding:  0 10px 10px;max-height: 240px;overflow-y: auto;}
.timeInfo .item{border-bottom: 1px solid #666;padding: 6px 0;}
.timeInfo .item .p1{font-weight: 500;color: #fff;}
.timeInfo .item .p2{font-weight: 500;color: #999;font-size: 12px;}

.warnmodal .ant-modal-content{background-color: #ff9200;color: #fff;}
.warnmodal h1{font-size: 24px;padding: 0 10px;}
.warnmodal .tip{line-height: 20px;font-weight: 500;padding: 0 10px 15px;}
.warnmodal .bgbai{border-radius: 4px;padding: 30px 20px 20px;}
.warnmodal .bgbai .flex{align-items: baseline;}
.warnmodal .rbox{margin-left: 10px;}
.warnmodal .desc{font-size: 12px;color: #999;margin-top: 10px;}
.warnmodal .rbox .ant-btn{font-size: 12px;}
.warnmodal .line{margin: 20px -20px;border-bottom: 1px solid #f5f5f5;}
.warnmodal .txbox{margin: 10px 0 0;width: 100%;justify-content: center;}
.warnmodal .txbox .ant-checkbox-inner{border-radius: 100%;}

.shopdetail{padding: 30px 0;}
.shopdetail .shopinfo{padding: 20px;}
.shopdetail .shopinfo .logo{display: block;width: 80px;height: 80px;margin-right: 20px;}
.shopdetail .shopinfo .title{font-size: 18px;font-weight: 500;margin-left: 8px;cursor: pointer;}
.shopdetail .shopinfo .title:hover{text-decoration: underline;}
.shopdetail .shopinfo .same{font-size: 14px;font-weight: 500;color: #666;line-height: 20px;cursor: pointer;}
.shopdetail .shopinfo .same .iconfont{line-height: normal;margin-left: 4px;}
.shopdetail .shopinfo .btn{color: hsla(0,0%,60%,.8);background-color: #fff;height: 36px;line-height: 36px;padding: 0 16px;border-radius: 18px;margin-left: 20px;cursor: pointer;}
.shopdetail .catebox{align-items: flex-start;margin: 20px 0;border-bottom: 1px solid #d4d1d1;}
.shopdetail .catebox .name{line-height: 60px;height: 60px;width: 100px;font-size: 12px;color: #999;}
.shopdetail .catebox .list{height: 60px;overflow: hidden;}
.shopdetail .catebox .list.auto{height: auto;}
.shopdetail .catebox .list p{line-height: 60px;height: 60px;font-weight: 500;color: #333;position: relative;margin-right: 40px;cursor: pointer;}
.shopdetail .catebox .list p.active{color: #11ba66;}
.shopdetail .catebox .list p.active::after{display: block;content: '';width: 100%;height: 4px;background-color: #11ba66;position: absolute;left: 0;bottom: 0;}
.shopdetail .catebox .more{width: 100px;line-height: 60px;text-align: right;font-size: 12px;color: #2e5aef;cursor: pointer;font-weight: 500;}
.shopdetail .catebox .more .iconfont{line-height: normal;margin-left: 4px;transition: .3s;}
.shopdetail .catebox .more.active .iconfont{transform: rotate(180deg);}
.shopdetail .list .item{width: calc((100% - 80px) / 5);margin-right: 20px;margin-bottom: 10px;background-color: #fff;border-radius: 4px;padding: 10px;}
.shopdetail .list .item:hover{box-shadow: 0 0 15px rgba(0,0,0,.2);transition: transform .2s linear,-webkit-transform .2s linear;transform: scale(1.05);}
.shopdetail .list .item:nth-child(5n){margin-right: 0;}
.shopdetail .list .pic{display: block;width: 100%;}
.shopdetail .list .item .title{font-size: 12px;margin-top: 4px;}
.shopdetail .list .item .pri{font-size: 18px;font-weight: 500;}

.filterbox{padding: 14px 0;margin-bottom: 20px;border-bottom: 1px solid #d4d1d1;color: #999;font-size: 12px;}
.filterbox .name{width: 100px;}
.filterbox .sortbtn{color: #666;margin-right: 40px;cursor: pointer;}
.filterbox .pbtn .iconfont{display: block;line-height: normal;}
.filterbox .pbtn .iconfont:first-child{margin-bottom: -12px;}
.filterbox .sortbtn.active{color: #11ba66;}
.filterbox .inptname{width: 230px;margin-left: auto;font-size: 12px;background-color: #f5f5f5;margin-right: 6px;}
.filterbox .inptname .ant-input-prefix{color: #999;}
.filterbox .inptpri{margin-left: 0;width: 80px;margin-left: 6px;}

.searchList .filterbox .pbtn{color: #999;}
.searchList .filterbox .pbtn .iconfont:first-child{margin-bottom: -5px;}
.searchList .filterbox .pbtn .iconfont{transform: scale(.7);}
.searchList .filterbox .pbtn>p{margin-left: -3px;}
.searchList .filterbox .inptpri{width: 120px;}

.disclaimer .ant-modal-header,.huihead .ant-modal-header{margin: -24px -24px 20px;padding: 15px 24px;background-color: #f5f5f5;}
.disclaimer .title{font-size: 24px;color: #333;}
.disclaimer h2{margin-top: 20px;}
.disclaimer p{font-size: 12px;color: #666;font-weight: 500;}
.disclaimer .flexCenter{padding-top: 30px;}
.disclaimer .left .ant-checkbox-wrapper{display: flex;font-size: 14px;color: #666;font-weight: 500;}
.disclaimer p.weight{color: #333;}

.transportBox{padding: 20px 0;}
.transportBox .t_banner{display: block;width: 100%;}
.transportBox .titbox{border-bottom: 1px solid #d4d1d1;padding: 16px 0;}
.transportBox .titbox .tt{display: block;width: 28px;border-radius: 2px;margin-right: 20px;}
.transportBox .titbox p{font-size: 30px;font-weight: 500;}
.transportBox .t1{margin-top: 20px;}
.transportBox .send_addr{background-image: url('./imgs/transport_addr.png');background-size: 100% 100%;padding: 20px;position: relative;margin-top: 10px;}
.transportBox .send_addr .p1{margin-left: 50px;color: #333;}
.transportBox .send_addr .label{margin-left: 50px;color: #999;width: 130px;}
.transportBox .send_addr .value{flex: 1;}
.transportBox .send_addr .flex{margin-top: 3px;}
.transportBox .send_addr .ant-btn{position: absolute;top: 20px;right: 20px;}
.transportBox .tip{margin-top: 10px;padding: 10px;background-color: #fcf8d9;font-size: 12px;color: #d61;}
.transportBox .t2{padding-top: 20px;margin-top: 30px;font-size: 18px;border-top: 1px solid #d4d1d1;}
.transForm{margin-top: 10px;}
.transForm .flex{align-items: flex-start;}
.transForm .ant-form-item-control-input{background-color: #f5f5f5;}
.transForm .ant-form-item{margin-bottom: 15px;}
.transForm .ant-form-item .ant-input{height: 36px;}
.transForm .icon-dingdanhuishouzhan{font-size: 30px;line-height: normal;margin-top: 30px;margin-left: 10px;cursor: pointer;}
.transForm .add{border-radius: 0;margin-top: 20px;height: 60px;color: #999;}
.transForm .add .iconfont{font-size: 24px;}
.transForm .flexAllCenter{margin-top: 15px;border-top: 1px solid #d4d1d1;padding-top: 30px;}
.transForm .flexAllCenter .ant-btn{height: 60px;border-radius: 0;width: 160px;font-size: 18px;}
.transForm .agreement{
  margin-top: 15px;
}
.translist .tbox{border-bottom: 1px solid #d4d1d1;padding: 16px;}
.translist .tbox .t1{font-size: 30px;color: #333;}
.translist .sbox{padding: 30px 0;}
.translist .sbox .ant-input-affix-wrapper{width: 300px;background-color: #f5f5f5;margin-left: auto;border-radius: 2px;}

.uniorderbox{padding: 30px 0;}
.uniorderbox .tit{padding-bottom: 20px;border-bottom: 1px solid #d4d1d1;color: #d5d5d5;}
.uniorderbox .tit i{margin: 0 20px;}
.uniorderbox .tit .active{color: #333;}
.uniorderbox .h1{font-size: 28px;font-weight: normal;margin-top: 30px;margin-bottom: 15px;}
.uniorderbox .h1 span{font-size: 12px;font-weight: normal;margin-left: 6px;}
.uniorderbox .ant-form-item-control-input{background-color: #f5f5f5;}
.uniorderbox .row10 .ant-form-item-control-input{max-width: 32%;}
.uniorderbox .picbtn{border: 1px dashed rgba(0,0,0,.15);border-radius: 4px;text-align: center;padding: 20px 0;background-color: #fff;font-size: 12px;color: #999;}
.uniorderbox .picbtn p{margin-top: 2px;}
.uniorderbox .ClsCost{padding: 30px 0 0;margin: 20px 0;border-top: 1px solid #d4d1d1;font-size: 28px;font-weight: normal;}
.uniorderbox .ClsCost img{width: 20px;margin-left: 10px;cursor: pointer;}
.uniorderbox .ant-input-group .ant-select-selector,.uniorderbox .ant-input-group .ant-input-group-addon{border: 0;}
.uniorderbox .ant-form-item-extra{font-size: 12px;color: #333;}
.uniorderbox .ant-form .left{margin-right: 30px;}
.uniorderbox .ant-form .left .ant-btn{padding-left: 0;}
.uniorderbox .foot{margin-top: 30px;}
.uniorderbox .foot>.ant-btn{width: 180px;height: 60px;border-radius: 4px;font-size: 18px;font-weight: 500;margin-left: 20px;}
.uniorderbox .ant-form{position: relative;}
.uniorderbox .totalpri{position: absolute;bottom: 128px;right: 0;}
.uniorderbox .totalpri h1{font-size: 40px;font-weight: 500;}
.uniorderbox .totalpri h1 span{font-size: 18px;}
.uniorderbox .totalpri p{font-size: 12px;text-align: right;}
.uniorderbox .totalpri p span{color: #2e5aef;cursor: pointer;}

.dzbox .notbox{padding: 30px;}
.dzbox .notbox h1{font-size: 50px;font-weight: 500;margin-bottom: 10px;color: #fff;}
.dzbox .notbox p{color: #fff;}
.dzbox .zgbox .ant-form-item-label{opacity: 0;}
.dzbox .hx{margin-bottom: 28px;}
.dzbox .ctmbtn .ant-form-item-control{flex: none;}
.dzbox .ctmbtn .ant-upload{width: 150px;}
.dzbox .ctmbtn .ant-upload-btn{padding: 39px 16px;}
.dzbox .foot{margin-top: 50px;}
.dzbox .ts{padding-left: 81px;}
.dzbox .uniorderbox .ctmbtn .ant-form-item-control-input{background-color: transparent;}

.userbox{padding: 30px 0;}
.userbox .tips{height: 40px;line-height: 40px;font-size: 14px;color: #333;margin-top: 30px;}
.userbox .tips img{display: block;width: 14px;margin-right: 6px;}
.userbox .cen{margin-top: 30px;align-items: flex-start;}
.userbox .usermenu{border: 1px solid #ddd !important;padding: 20px 0;border-radius: 4px;}
.userbox .usermenu .ant-menu-item{margin-left: 0;margin-right: 0;width: 100%;border-radius: 0;font-weight: bold;position: relative;}
.usermenu.ant-menu-light .ant-menu-item-selected::after{display: block;content: '';width: 4px;height: 100%;background-color: #11ba66;position: absolute;right: 0;top: 0;}
.userbox .ant-menu .ant-menu-item-divider{border-color: #eee;}
.userbox .content{padding: 0 20px;}

.userhome .info{border-radius: 4px 4px 0 0;background: linear-gradient(180deg,#3a3a3a,#515151);padding: 20px 0;margin-bottom: 10px;}
.userhome .info .lbox{width: 22%;text-align: center;}
.userhome .info .lbox .avatarbox{width: 100px;height: 100px;position: relative;margin: 0 auto;}
.userhome .info .lbox .avatar{display: block;width: 100%;height: 100%;border-radius: 100%;}
.userhome .info .lbox .avatarbox span{font-size: 12px;color: #fff;width: max-content;height: max-content;min-width: 24px;min-height: 24px; border-radius: 100%;background-color: #f6ce52;text-align: center;line-height: 24px;position: absolute;right: 0;bottom: 0;font-weight: bold;}
.userhome .info .lbox p{font-size: 12px;color: #fff;}
.userhome .info .lbox .nick{margin: 8px 0 3px;}
.userhome .info .rbox>div{text-align: center;}
.userhome .info .rbox .p1{color: #999;}
.userhome .info .rbox .p2{font-size: 30px;color: #fff;}
.userhome .info .rbox .ant-btn{font-size: 12px;background-color: transparent;color: #fff;line-height: 22px;}

.cart_table .tbox{padding: 10px 0;}
.cart_table .head{line-height: 36px;color: #4f4f4f;margin-top: 10px;margin-bottom: 20px;}
.cart_table .card_item{font-size: 12px;}
.cart_table .tbox .ant-checkbox-wrapper{align-items: center;}
/* .cart_table .tbox .tbox+span{display: flex;align-items: center;} */
.cart_table .tbox img{margin-right: 4px;margin-left: 10px;}
.cart_table .tbox>span{font-size: 14px;cursor: pointer;user-select: none;}
.cart_table .box{border: 1px solid #d4d1d1;margin-bottom: 20px;}
.cart_table .box .child{padding: 12px 0;}
.cart_table .box .pic{display: block;width: 80px;height: 80px;margin-right: 10px;border-radius: 5px;}
.cart_table .box .title:hover{color: #11ba66;}
.cart_table .color_type{color: #999;margin-top: 4px;}
.cart_table .infos{width: 33.3333333%;}
.cart_table .remarkbox{width: 16.66667%;text-align: center;}
.cart_table .pribox,.cart_table .goods_num,.cart_table .totalbox,.cart_table .czl{width: 12.5%;text-align: center;}
.cart_table .bqbox{padding-left: 116px;margin-top: 4px;}
.cart_table .bqbox .icon-tianjiabiaoqian{font-size: 12px;}
.cart_table .child>.flex{align-items: flex-start;}
.cart_table .goods_num{padding: 0 10px;}
.cart_table .infos .wzk{display: flex;flex-direction: column;align-self: flex-start;}
.cart_table .czl p{cursor: pointer;line-height: 20px;}

.fixbot{font-size: 12px;padding: 10px;border: 2px solid #ddd;}
.fixbot .ant-checkbox-wrapper,.fixbot .ant-btn.ant-btn-sm{font-size: 12px;font-weight: 500;}
.fixbot .total_pri .pri{font-size: 24px;font-weight: 500;color: #11ba66;text-align: right;}
.fixbot .bot{justify-content: flex-end;}
.fixbot .total_pri span{font-weight: 500;}
.fixbot .total_pri{margin-right: 10px;text-align: right;font-size: 14px;}
.fixbot .bot .ant-btn{height: 50px;}
.fixbot .selt{border-bottom: 1px solid #d4d1d1;padding: 12px 0;margin-bottom: 8px;}
.fixbot .bot .ant-btn-primary{font-size: 18px;}

/* .colorboxs .ant-dropdown-menu{display: flex;flex-wrap: wrap;}
.colorboxs .ant-dropdown-menu .ant-dropdown-menu-title-content{width: 20px;height: 20px;flex: none;}
.colorboxs .ant-dropdown-menu-title-content span{display: block;width: 100%;height: 100%;border-radius: 100%;} */
.colorkk{display: block;width: 20px;height: 20px;border-radius: 100%;position: relative;}
.colorkk.active::after{display: block;content: '';width: 12px;height: 12px;background-image: url('./imgs/gou.png');background-size: 100% 100%;position: absolute;left: 50%;top: 50%;margin-left: -6px;margin-top: -6px;}
.colorboxs{width: 144px;padding: 12px;}
.colorboxs .rc-virtual-list-holder-inner{flex-direction: initial !important;flex-wrap: wrap;}
.colorboxs .ant-select-item{padding: 0;position: relative;min-height: auto;margin: 5px 10px;}
.colorboxs.ant-select-dropdown .ant-select-item-option-selected{background-color: transparent;}
.colorboxs.ant-select-dropdown .ant-select-item-option-state{font-size: 12px;position: absolute;left: 50%;top: 50%;margin-top: -6px;margin-left: -6px;}
.colorboxs.ant-select-dropdown .ant-select-item-option-selected .ant-select-item-option-state{color: #fff;}
.colorselt .ant-select-selection-overflow{margin-left: 10px;}

.delboxxx.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover{background-color: #e1fae9;color: #11ba66;}

.sctabs .ant-tabs-tab-active{font-weight: bold;}

.sclist{padding: 10px;}
.shopItem{border-radius: 4px;box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);padding: 20px;margin-bottom: 20px;align-items: flex-start;}
.shopItem .pic{display: block;width: 80px;margin-right: 20px;}
.shopItem .rinfo .icon-dingdanhuishouzhan{font-size: 20px;color: #d8d8d8;}
.shopItem .rinfo .icon-dingdanhuishouzhan:hover{color: #333;}
.shopItem .samebox{margin-top: 30px;}
.shopItem .rinfo .inptbox{background-color: #333;height: 28px;border-radius: 14px;color: #fff;}
.shopItem .rinfo .inptbox .ant-input{color: #fff;font-size: 12px;font-weight: bold;}

.goodsItem{width: 22%;margin-right: 3%;margin-bottom: 20px;box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);position: relative;}
.goodsItem .pic{display: block;width: 100%;}
.goodsItem .title{margin: 5px 0;padding: 0 6px;}
.goodsItem .title img{margin-bottom: -5px;margin-right: 4px;}
.goodsItem .pri{padding: 0 6px 6px;}
.goodsItem .icon-dingdanhuishouzhan{color: #d8d8d8;display: block;width: fit-content;margin-left: auto;margin-right: 8px;}
.goodsItem .icon-dingdanhuishouzhan:hover{color: #333;}
.goodsItem .ant-checkbox-wrapper{position: absolute;left: 10px;top: 10px;}

.sclist .goodsItem{box-shadow: none;cursor: pointer;}
.sclist .goodsItem .pri{padding-bottom: 0;}
.sclist .goodsItem .icon-dingdanhuishouzhan{margin-top: -10px;}
.sclist .goodsItem:hover{box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);}

.add_to_order{font-size: 24px;color: #d8d8d8;height: 60px;}
.add_to_order i{font-size: 24px;}

.order_filter{padding: 20px 0;border-bottom: 1px solid #ddd;}
.order_filter p{cursor: pointer;display: flex;align-items: center;margin-left: 20px;}
.order_filter p:hover{color: #11ba66;}
.order_filter p i{margin-right: 4px;margin-bottom: -1px;}

.top_search_order{padding-top: 20px;}
.top_search_order .others{height: 0;overflow: hidden;}
.top_search_order .others.active{height: auto;}
.top_search_order .otherLock{user-select: none;}
.top_search_order .otherLock:hover{color: #11ba66;}

.order_table{padding: 20px 0;}
.order_table .card_item{margin-bottom: 20px;font-size: 12px;}
.order_table .card_item .tbox{padding: 8px 0;margin-top: 20px;}
.order_table .card_item .ydsbtn{line-height: 16px;border-radius: 8px;padding: 0 6px;}
.order_table .child{margin-bottom: 10px;border: 1px solid #d4d1d1;}
.order_table .child .pic{display: block;width: 80px;height: 80px;}
.order_table .child .title:hover{color: #11ba66;}
.order_table .item>.cellinfo>div{text-align: center;}
.order_table .item>.flex .infos{text-align: left;align-items: flex-start;}
.order_table .pribox{width: 8.7%;}
.order_table .numbox{width: 7%;}
.order_table .ztbox,.order_table .shbox{width: 9.8%;}
.order_table .fjbox{width: 8.8%;}
.order_table .shbox{border-right: 1px solid #d4d1d1;}
.order_table .totalbox{width: 11%;border-right: 1px solid #d4d1d1;}
.order_table .czbox{width: 12.5%;}
.order_table .lxbox{width: 12.5%;}
.order_table .lxbox>span{max-width: 85%;}
.order_table .fjbox p,.order_table .shbox p,.order_table .czbox p{cursor: pointer;}
.order_table .fjbox p:hover,.order_table .shbox p:hover,.order_table .czbox p:hover{color: #11ba66;}
.order_table .item .cellinfo>div{padding: 10px 0 0;}
.order_table .othercz{width: 65.5%;border-right: 1px solid #d4d1d1;padding-left: 126px;padding-right: 10px;padding-bottom: 10px;}
.order_table .othercz .bzkk{margin: 4px 0;}
.order_table .othercz .goods_photo{padding: 5px;border-radius: 4px;}
.order_table .othercz .goods_photo .ant-image{margin-right: 6px;}
.order_table .othercz .goods_photo .iconfont{font-size: 24px;cursor: pointer;margin-left: 6px;}
.order_table .historybox{width: 65.5%;padding: 0 10px;border-right: 1px solid #d4d1d1;}
.order_table .historybox .more{border-bottom: 1px solid rgba(221,102,17,.1);background-color: #fcf8d9;color: #d61;font-weight: 500;line-height: 32px;cursor: pointer;}
.order_table .historybox .cont{padding: 10px;background-color: #fcf8d9;color: #d61;}
.order_table .historybox .cont .nick{color: rgba(221,102,17,.5);}
.order_table .historybox .cont .neir{width: 75%;}
.order_table .historybox .cont.user{text-align: right;}
.order_table .historybox .cont.user .neir{margin-left: auto;}
.order_table .fwbgq .a_title{flex: 1;}
.order_table .fwbgq .a_price{width: 16%;}
.order_table .fwbgq .a_qty{width: 10%;}
.order_table .fwbgq .a_control{width: 27%;}


.finebox{align-items: flex-start;padding: 10px;background-color: #f2f7ff;font-size: 12px;}
.finebox .pic{display: block;width: 80px;height: 80px;margin-right: 20px;}
.dzpzbox .flexCenter{padding-top: 0;}
.finebox .gtitle{padding: 10px;max-width: 50%;}
.finebox .gtitle p{color: #ef7b17;}
.finebox .goods_num{background-color: #f5f7fa;border: 1px solid #dcdfe6;border-radius: 4px;margin-left: 2px;}
.finebox .goods_num img{width: 12px;padding: 9px;}
.finebox .goods_num img:first-child{border-right: 1px solid #dcdfe6;}
.finebox .goods_num img:last-child{border-left: 1px solid #dcdfe6;}
.finebox .goods_num input{max-width: 100px;background-color: #fff;}

.dashbtn{width: 48px;height: 48px;border-radius: 6px;border: 1px dashed #c0ccda;background-color: #fbfdff;display: flex;align-items: center;justify-content: center;}
.dashbtn img{width: 20px;}
.dzpzbox .cont{padding: 10px;background-color: #f2f7ff;margin-top: 8px;}
.dzpzbox .icon-dingdanhuishouzhan{font-size: 24px;font-weight: 500;line-height: normal;margin-left: 10px;margin-top: 12px;}
.dzpzbox .ant-form .ant-form-item-label label{font-size: 12px;}
.dzpzbox .ant-form-item.txtt{width: 60%;}
.dzpzbox .ant-form-item{margin-bottom: 18px;}
.dzpzbox .tipss{padding: 10px;border-bottom: 1px dashed #eee;}
.dzpzbox .service{margin-top: 10px;text-align: right;color: #2f926d;}
.dzpzbox .ant-modal-footer{margin-top: 30px;text-align: center;}
.dzpzbox .ant-modal-footer .ant-btn{border-radius: 16px;}
.dzpzbox .ant-form-item .ant-form-item-explain{font-size: 12px;}

.order_step{display: flex;align-items: flex-start;padding: 0 20px 30px;border-bottom: 1px solid #d4d1d1;}
.order_step>div{text-align: center;font-size: 12px;color: #999;}
.order_step .line{flex: 1;border-bottom: 2px dashed #efeeef;margin: 34px 10px 0;}
.order_step .line.active{border-bottom-color: #11ba66;}
.order_step .icon{width: 70px;height: 70px;background-image: url('./imgs/order_detail_icons.png');background-repeat: no-repeat;background-position-y: -71px;}
.order_step div p{padding-top: 10px;}
.order_step>div.active p{color: #11ba66;}
.order_step .icon2{background-position-x: -71px;}
.order_step .icon3{background-position-x: -142px;}
.order_step .icon4{background-position-x: -213px;}
.order_step .icon5{background-position-x: -284px;}
.order_step .active .icon{background-position-y: 0;}
.order_status_box{padding: 30px 30px 0;color: #4f4f4f;}
.order_status_box .reminder{margin-top: 20px;padding: 20px 30px;background-color: #fcf8d9;border-radius: 4px;font-size: 12px;color: #d61;}
.order_tracks{padding-left: 20px;margin: 30px 0;font-size: 12px;color: #999;max-width: 94%;}
.order_tracks.ant-timeline .ant-timeline-item-head{width: 22px;height: 22px;border: 0;background-image: url('./imgs/gou1.png');background-size: 100% 100%;}
.order_tracks.ant-timeline .ant-timeline-item-tail{height: calc(100% - 32px);inset-block-start: 27px;inset-inline-start: 10px;}
.order_tracks.ant-timeline .ant-timeline-item:first-child .ant-timeline-item-head{background-image: url('./imgs/gou1s.png');}
.order_tracks.ant-timeline .ant-timeline-item-content{margin-inline-start: 40px;padding: 10px 15px;inset-block-start: -10px;}
.order_tracks.ant-timeline .ant-timeline-item{font-size: 12px;}
.order_tracks.ant-timeline .ant-timeline-item:first-child .ant-timeline-item-content{background-color: #11ba66;color: #fff;border-radius: 4px;position: relative;}
.order_tracks.ant-timeline .ant-timeline-item:first-child .ant-timeline-item-content::before{display: block;content: '';width: 0;height: 0;border-top: 7px solid transparent;border-bottom: 7px solid transparent;border-right: 7px solid #11ba66;position: absolute;left: -7px;top: 14px;}

.storage_list .othercz,.storage_list .historybox{width: 100%;border-right: 0;}
.storage_list .historybox{padding-left: 126px;}

.rkpopbox .box p{line-height: 30px;cursor: pointer;user-select: none;padding: 0 15px;}
.rkpopbox .box p:hover{background-color: #e9ebeb;}

.dzpzbox .tips{border-radius: 4px;padding: 10px;background-color: #ff4700;color: #fff;}
.dzpzbox .goods_pic{display: block;margin-top: 10px;width: 100px;height: 100px;}
.dzpzbox .inpt_inner{background-color: #f8f8f8;margin-top: 10px;height: 40px;}

.par_nav p{border: 2px solid transparent;color: #5f5e5e;line-height: 40px;border-radius: 4px;cursor: pointer;min-width: 102px;padding: 0 5px;text-align: center;user-select: none;}
.par_nav p.active{border-color: #11ba66;color: #11ba66;background-color: rgba(17,186,102,.1);font-weight: 500;}

.pl_tabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{font-weight: bold;}
.pl_tabs .ant-tabs-extra-content{background-color: #f5f5f5;}
.pl_tabs.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar{height: 3px;}

.moneybox{box-shadow: 0 2px 10px 0 rgba(0,0,0,.15);border-radius: 0 0 4px 4px;background-color: #fff;margin-bottom: 30px;}
.moneybox .top{padding: 20px 30px;background-color: #11ba66;color: #fff;}
.moneybox .top .pri{font-size: 40px;font-weight: 500;}
.moneybox .bot{padding: 20px;}

.reptbox{margin-top: 20px;}
.reptbox .left{color: #fff;padding: 20px;border-radius: 12px 0 0 12px;text-align: center;width: 35%;}
.reptbox .left .ant-picker{display: block;text-align: left;}
.reptbox .left .ant-picker input{color: #fff;font-weight: 500;width: 64px;}
.reptbox .left .ant-picker .ant-picker-suffix{color: #fff;}
.reptbox .left .p1{font-size: 32px;font-weight: bold;margin: 0 0 10px;}
.reptbox .rbox{border: 1px solid #d4d1d1;border-left: 0;border-radius: 0 12px 12px 0;}

.consumption .left{border: 1px solid #d4d1d1;border-radius: 12px;flex: 1;}
.consumption .left h4{padding: 20px 0 0 20px;}
.consumption .left .qq{position: relative;}
.consumption .left .qq span{width: 14px;height: 14px;border-width: 1px;border-style: solid;background-color: #fff;position: absolute;border-radius: 100%;}
.consumption .left .qq span:first-child{border-color: #fff #d4d1d1 #d4d1d1 #fff;left: 0;transform: translate(-50%,-50%) rotate(-45deg);}
.consumption .left .qq span:last-child{border-color: #d4d1d1 #fff #fff #d4d1d1;right: 0;transform: translate(50%,-50%) rotate(-45deg);}
.consumption .rbox{width: 35%;border: 1px solid #d4d1d1;border-radius: 12px;padding: 20px;}

.coupon_code{display: flex;justify-content: center;border-bottom: 1px solid #d4d1d1;padding: 5px 0 45px;margin-bottom: 30px;}
.coupon_code .ant-input{border-radius: 0;background-color: #f5f5f5;height: 60px;font-size: 16px;max-width: 360px;}
.coupon_code .ant-btn{width: 100px;height: 60px;border-radius: 0;font-weight: 500;}
.coupon_nav p{border-width: 1px;padding: 0 20px;}
.couponlist{padding: 20px 0;}
.couponlist .item{margin-bottom: 20px;box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);border-radius: 4px;overflow: hidden;position: relative;}
.couponlist .item .top{color: #fff;padding: 10px 0;}
.couponlist .item .pri{font-size: 50px;line-height: 1;}
.couponlist .item .type{padding: 1px 2px;background-color: #333;opacity: .3;display: inline-block;border-radius: 2px;}
.couponlist .item .mid{padding: 10px;}
.couponlist .item .bq{position: absolute;left: 0;top: 0;}
.couponlist .item .bq .bg{border-width: 25px;border-style: solid;border-color: #f22d1b transparent transparent #f22d1b;border-top-left-radius: 4px;}
.couponlist .item .bq span{position: absolute;top: 10px;width: 68px;left: -16px;text-align: center;font-weight: bold;color: #fff;transform: rotate(-45deg);box-shadow: 0 5px 2px -2px rgba(0,0,0,.1);}

.intgl_top{padding: 20px 30px;border-radius: 4px;color: #fff;}
.intgl_top .left span{font-size: 40px;font-weight: bold;line-height: 1.5;}

.zxtabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{font-weight: bold;}
.zxtabs.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar{height: 3px;}

.mtop{border-bottom: 1px solid #d4d1d1;padding-bottom: 20px;}
.mtop p{padding: 8px 20px;cursor: pointer;user-select: none;}

.site-detail_box.ant-modal .ant-modal-body{margin: -24px -24px 0;}
.site-detail_box .container{display: block;max-height: 600px !important;width: 100%;overflow-y: scroll;}
.site-detail_box .tab-h3{width: 50%;margin: 20px auto !important;height: auto;}
.site-detail_box .footer{padding: 0;height: auto;}
.site-detail_box .footer div{line-height: 26px;}
.site-detail_box .time{text-align: right;padding: 20px 20px 0;}

.info_cont{padding-right: 60px;color: #fff;}
.info_cont .flwp>div{margin-bottom: 10px;}
.info_cont .ant-progress-line{margin-bottom: 0;}
.info .editbtn{position: absolute;top: 10px;right: 10px;cursor: pointer;color: #fff;}

.levelbox .first{width: 21%;}
.levelbox .col{width: 10%;}
/* .levelbox .item{border: 2px solid transparent;} */
.levelbox .item .td{border-bottom: 1px solid #ccc;height: 60px;display: flex;flex-direction: column;justify-content: center;}
.levelbox .item.active{border: 2px solid #11ba66;transform: translateY(-2px);box-sizing: initial;color: #11ba66;}
.levelbox .item.active .td.weight600{background-color: #11ba66;color: #fff;}
.levelbox .col .td{padding-left: 20px;}

.edit_size_box .pic{display: block;width: 277px;}
.edit_size_box .rbox{margin-left: 24px;}

.countrylist{width: 740px;max-height: 44vh;overflow-y: auto;}
.countrylist .item{width: 25%;padding: 10px 10px 10px 0;align-items: flex-start;cursor: pointer;}
.countrylist .item img{width: 18px;margin-top: 4px;}
.countrylist .item:hover{color: #11ba66;}

.secInfo{color: #fff;}
.secInfo .lw{width: 120px;}
.secInfo .htips{margin-left: 120px;}
.secInfo .yxdz{font-size: 20px;}

.cadrbox{background: url('./imgs/transport_addr.png') 0/100% 100% no-repeat;margin-bottom: 30px;padding: 30px;font-size: 12px;}
.cadrbox h1{font-size: 24px;}
.cadrbox .no-data{background-color: hsla(0,0%,100%,.7);margin: -30px;padding: 40px 30px;display: flex;flex-direction: column;justify-content: center;align-items: center;}

.mtabs .item{flex: 1;margin-right: 10px;border-radius: 4px;border: 1px solid #ddd;padding: 20px 10px 15px;text-align: center;cursor: pointer;user-select: none;}
.mtabs .item:last-child{margin-right: 0;}
.mtabs .item .icon{display: block;width: 60px;height: 60px;background-image: url('./imgs/trans1.png');background-size: auto 60px;border-radius: 50%;margin: 0 auto 10px;}
.mtabs .item1 .icon{background-position-x: -60px;}
.mtabs .item2 .icon{background-position-x: -121px;}
.mtabs .item3 .icon{background-position-x: -305px;}
.mtabs .item4 .icon{background-position-x: -243px;}
.mtabs .item.active .icon{background-image: url('./imgs/trans.png');}
.mtabs .item.active{border-color: #11ba66;color: #11ba66;background-color: rgba(17,186,102,.1);}
.mtabs .item.active p{color: #11ba66;}
.mtabs .item p:last-child{margin-top: 4px;}
.mtabs .item3 p:nth-child(2){margin-left: -10px;margin-right: -10px;}

.pushlist{margin-top: 30px;padding-bottom: 0px;border-bottom: 1px solid #d4d1d1;}
.pushlist h4{width: 70px;}
.pushlist .ant-checkbox-group-item{width: 32%;margin-bottom: 20px;font-size: 12px;color: #606266;font-weight: 500;}
.pushlist .ant-checkbox-wrapper-checked{color: #11ba66;}
.pushlist .ant-checkbox .ant-checkbox-inner{border-radius: 2px;width: 14px;height: 14px;}

.sybox .ant-radio-wrapper span.ant-radio-checked+span{color: #11ba66;}

.promyBox{border-radius: 4px;background-color: #005ae9;overflow: hidden;position: relative;}
.promyBox .ant-carousel{background-color: rgba(0,0,0,.6);border-radius: 21px;width: fit-content;min-width: 320px;max-width: 32%;}
.promyBox .swiper .flexCenter{display: flex !important;color: #fff;padding: 6px 12px;}
.promyBox .swiper .avatar{display: block;width: 30px;height: 30px;border-radius: 100%;}
.promyBox .poabs{position: absolute;left: 0;top: 28px;padding-left: 15px;padding-right: 20px;}
.promyBox .poabs>span{display: block;line-height: 20px;border-radius: 8px;background-color: rgba(0,0,0,.2);padding: 5px 10px;font-size: 12px;color: #fff;font-weight: 500;cursor: pointer;}
.commission_box{margin: 0 30px;border-radius: 12px;overflow: hidden;}
.commission_box .head{height: 74px;background: #fff linear-gradient(134deg,#ffea86,#ffd04e);padding: 0 20px;}
.commission_box .head .left{align-items: baseline;font-size: 12px;}
.commission_box .head h1{font-size: 28px;line-height: 34px;margin-right: 20px;}
.commission_box .head .left div>span{margin-right: 30px;}
.commission_box .bgbai{padding: 20px;}
.commission_box .yubox span{font-size: 40px;line-height: 56px;}
.commission_box .yubox i{margin-top: 5px;}
.commission_box .list{margin-top: 10px;}
.commission_box .list .item32{padding: 20px;border-radius: 4px;opacity: .8;}
.commission_box .list .item32>div>span{font-size: 20px;font-weight: bold;}
.commission_box .list .item32>div>i{margin-top: 4px;}
.commission_box .list .item32 .ant-btn{margin-top: 6px;}
.flow_chart{padding: 20px 40px;border-radius: 12px;margin: 20px 30px 0;}
.flow_chart .flex{margin-top: 15px;}
.flow_chart .rowFlex{margin-right: 45px;}
.flow_chart .rowFlex:last-child{margin-right: 0;}
.flow_chart .rowFlex img{display: block;width: 100%;margin-bottom: 12px;}
.qrcodebox{margin: 20px 30px;border-radius: 12px;background: hsla(0,0%,100%,.1);border: 1px solid hsla(0,0%,100%,.3);padding: 20px 0;color: #fff;}
.qrcodebox .flex{padding: 0 30px;margin-top: 30px;}
.qrcodebox .item{margin-right: 54px;position: relative;flex: 1;}
.qrcodebox .item:last-child{margin-right: 0;}
.qrcodebox .item .btn{height: 70px;border-radius: 8px;background-color: #ffe300;color: #333;font-size: 18px;color: #333;font-weight: bold;transition: all .3s;cursor: pointer;}
.qrcodebox .item .btn span{width: 64px;height: 21px;border-radius: 8px 0 8px 0;background-color: #333;position: absolute;left: 0;top: 0;font-size: 12px;font-weight: bold;text-align: center;line-height: 21px;color: #fff;}
.qrcodebox .item>span{display: block;margin-top: 6px;font-size: 12px;text-decoration: underline;text-align: center;cursor: pointer;}
.qrcodebox .item .btn:hover{transform: translateY(-7px) scale(1.2);}

.tgmask .ant-modal-content p{line-height: 24px;color: rgba(0,0,0,.54);}

.qybox .list{border: 1px solid #d4d1d1;}
.qybox .list .item1{width: 220px;padding-top: 20px;padding-bottom: 15px;}
.qybox .list .item1 .avatar{display: block;width: 50px;height: 50px;border-radius: 100%;margin: 0 auto 6px;}
.qybox .list .rowFlex{border-left: 1px solid #d4d1d1;padding-top: 50px;}
.qybox .list>div{display: flex;flex-direction: column;align-items: center;}
.qybox .list .level{line-height: 20px;border-radius: 10px;font-size: 12px;color: #fff;background-color: hsla(0,0%,60%,.3);padding: 0 20px;font-weight: 500;}
.qybox .list .rate{font-size: 28px;line-height: 40px;}
.qybox .item1 .ant-progress{width: 180px;margin: 0 auto;}
.qybox .item1 .rinfo{width: 180px;margin-top: -4px;}

.checkorderbox h1{padding: 20px 10px;}
.checkorderbox .orderbox{padding: 20px;border: 1px solid #eee;}
.checkorderbox .conbox{padding: 20px;border-radius: 8px;margin-bottom: 20px;}
.checkorderbox .conbox .flexCenter>span{display: block;width: 18px;height: 18px;border-radius: 100%;background-color: #333;font-size: 16px;font-weight: bold;color: #fff;text-align: center;line-height: 18px;}
.checkorderbox .goods{padding: 10px 0 30px;}
.checkorderbox .goods .child{margin-top: 20px;border: 1px solid #d4d1d1;}
.checkorderbox .goods .tbox{padding: 10px 10px 0;}
.checkorderbox .goods .tbox .store{padding: 0 9px;border: 1px solid #11ba66;border-radius: 12px;color: #11ba66;}
.checkorderbox .goods>div{text-align: center;}
.checkorderbox .goods .head{padding: 18px 0;border-top: 1px solid #ddd;border-bottom: 1px solid #ddd;}
.checkorderbox .goods .item1{width: 32%;}
.checkorderbox .goods .item2{width: 18%;}
.checkorderbox .goods .item3{width: 12.5%;}
.checkorderbox .goods .item{padding: 10px 0;background-color: #f5f5f5;overflow: hidden;margin-top: 10px;}
.checkorderbox .goods .item1 .width80{margin-left: 20px;border-radius: 5px;}
.checkorderbox .goods .item1 .title:hover{color: #11ba66;}
.checkorderbox .goods .total{padding: 10px;}
.checkorderbox .goods .total>div{padding: 3px 0;justify-content: flex-end;}
.checkorderbox .goods .total>div p,
.checkorderbox .goods .total>div .pri{margin-left: 60px;margin-right: 30px;}
.checkorderbox .goods .total>div:last-child{padding: 10px 0 0;border-top: 1px dashed #d4d1d1;margin-top: 2px;}
.checkorderbox .goods .total>div:last-child p,
.checkorderbox .goods .total>div:last-child .pri{font-size: 20px;font-weight: bold;color: #11ba66;}
.checkorderbox .all{padding: 20px 40px;justify-content: flex-end;}
.checkorderbox .all span{color: #9c9ea6;margin-right: 40px;font-weight: bold;}
.checkorderbox .all p,
.checkorderbox .all .pri{font-size: 30px;font-weight: bold;color: #11ba66;}
.checkorderbox .confirmbox{padding-right: 40px;padding-bottom: 30px;padding-top: 20px;text-align: right;}
.checkorderbox .confirmbox .readme>span{cursor: pointer;}
.checkorderbox .confirmbox .readme>span:hover{color: #11ba66;text-decoration: underline;}

.paymentbox{padding-top: 30px;}
.paymentbox .h1{font-size: 24px;}
.paymentbox .cont{margin-top: 30px;border: 1px solid #d4d1d1;}
.paymentbox .cont .item{padding: 25px 0px 10px;border-bottom: 1px solid #d4d1d1;margin: 0;align-items: flex-start;}
.paymentbox .cont .item:last-child{border-bottom: 0;}
.paymentbox .cont .item .icon{display: block;height: 50px;margin-right: 10px;}
.paymentbox .cont .item .ant-radio{margin-left: 20px;align-self: initial;margin-top: 18px;}
.paymentbox .cont .item .sub_icon{max-height: 30px;max-width: 100%;}
.paymentbox .cont .item .ant-radio+span{flex: 1;}
.paymentbox .cont .item .desc{text-align: right;}
.paymentbox .optbox .flwp p{height: 30px;border-radius: 15px;min-width: 65px;border: 1px solid #d8d8d8;margin-right: 10px;cursor: pointer;color: #666;padding: 0 18px;line-height: 28px;}
.paymentbox .optbox .flwp p.active{color: #11ba66;border-color: #11ba66;background-color: rgba(17,186,102,.1);}
.paymentbox .optbox>p{padding: 6px 10px;border-radius: 2px;margin-top: 12px;}
.payed{padding: 20px 0;}
.paymentbox+div>.ant-affix{box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);}

.zffkbox .icon{height: 50px;}
.zffkbox .sub{max-height: 15px;max-width: 100%;}
.zffkbox .bgf5{padding: 20px 20px 20px 10px;border-radius: 4px;}
.zffkbox .add{width: 80px;height: 80px;border-radius: 6px;border: 1px dashed #d9d9d9;justify-content: center;align-items: center;cursor: pointer;}
.zffkbox .add span{margin-top: 4px;}

.aboutbox{margin-top: 60px;margin-bottom: 60px;align-items: flex-start;}
.aboutbox .left{width: 250px;border-radius: 5px;margin-right: 60px;overflow: hidden;padding-bottom: 100px;}
.aboutbox .left div{cursor: pointer;margin: 50px;position: relative;}
.aboutbox .left div.active{font-weight: bold;color: #11ba66;}
.aboutbox .left div.active::before{display: block;content: '';width: 6px;height: 100%;background-color: #11ba66;position: absolute;left: -50px;top: 0;}
.aboutbox .h1{line-height: 66px;border-bottom: 1px solid #e9ebeb;padding-left: 30px;}
.aboutbox .cont{padding: 35px 30px 50px;}
.aboutbox .cont img{max-width: 100%;}
.aboutbox .cont p{font-size: 16px;line-height: 32px;}
.aboutbox .ant-btn i{font-size: 22px;font-weight: normal;}
.lxwmbox h2{line-height: 54px;}

.loginbox{background-color: #060d18;}
.loginbox .bg{display: block;width: 850px;}
.loginbox>div{align-items: flex-start;position: relative;}
.loginbox .box{width: 340px;height: 670px;border-radius: 4px;position: absolute;right: 0;top: 30px;padding: 10px;}
.loginbox .ewm{width: 56px;}
.loginbox .ver_code,.fgbox .ver_code{width: 150px;height: 40px;border: 1px solid #eee;margin-left: 10px;}
.loginbox .ver_code img,.fgbox .ver_code img{display: block;width: 100%;height: 100%;}
.loginbox .ant-divider .ant-divider-inner-text{color: #999;}
.loginbox .other img{display: block;width: 26px;height: 26px;}
.loginbox .other p{padding: 5px;border-radius: 100%;border: 1px solid #e5e5e5;cursor: pointer;}
.loginbox .ant-form .ant-input{height: 40px;}
.loginbox .ant-form .ant-form-item-control-input{background-color: #f8f8f8;}
.loginbox .ant-form .ckbox .ant-form-item-control-input{background-color: transparent;}
.loginbox .ckbox .ant-checkbox{align-self: flex-start;}

.login_footer{margin-top: 12px;}
.login_footer .marginr10{cursor: pointer;}
.login_footer .marginr10:hover{color: #11ba66;}
/* .login_footer .marginr10:nth-child(2){margin-left: 4px;margin-right: 40px;} */
.login_footer span:first-child{margin-right: 40px;}

.loginbox .scan{display: block;width: 58px;margin: 10px auto 0;}
.loginbox .ant-qrcode{margin: 30px auto 0;padding: 8px;}
.loginbox h2.center{margin-top: 50px;}
.loginbox .treg{position: absolute;right: 20px;bottom: 20px;}
.loginbox .ant-input-affix-wrapper:focus-within{box-shadow: none;}

.xcxx .ant-tooltip-inner{min-height: auto;padding-top: 4px;padding-bottom: 4px;}
.xcxx{
  z-index: 100 !important;
}
.fgbox h1{line-height: 80px;text-align: center;background-color: #11ba66;font-size: 24px;color: #fff;}
.fgbox .bgbai{padding-bottom: 40px;overflow: hidden;}
.fgbox .box{width: 450px;margin: 0 auto;}
.fgbox .bgbai h2{line-height: 100px;text-align: center;margin-top: 10px;}
.fgbox .ant-form .ant-input{height: 30px;}
.fgbox .ant-btn{width: 300px;display: block;margin-left: auto;height: 40px;font-weight: bold;}

.searchList{border-radius: 12px;}
.searchList .list .item{margin-right: 15px;border-radius: 8px;}
.searchList .list .item:hover{transform: none;}
.tjbox .list .item .pic{border-radius: 8px 8px 0 0;}

.linesbox{padding: 10px 0 10px 40px;max-height: 150px;overflow: auto;}
.linesbox .item{margin-right: 20px;cursor: pointer;padding: 10px 0;width: 130px;border-radius: 4px;border: 1px solid transparent;}
.linesbox .item.active{border-color: #11ba66;background-color: #e4fcf0;}
.linesbox .item .icon{display: block;width: 50px;margin: 0 auto 4px;}
.linesbox .item:hover{background-color: #e4fcf0;}

.entryBox{margin: 15px 30px;border: 1px solid #d4d1d1;}
.entryBox>div{padding: 6px 0;}
.entryBox .goods_photo{min-height: 40px;margin-left: calc(5% + 12px);margin-right: 20px;margin-top: 6px;padding-left: 8px;}

.line_content{border: 1px solid #d4d1d1;padding: 0 10px;margin-top: 15px;min-height: 150px;}
.line_content .item:last-child{border-bottom: 0;}
.line_content .item .logo{width: 40px;height: 40px;position: relative;margin: 0 auto;}
.line_content .item .logo img{display: block;width: 100%;}

.parcelList{min-height: 280px;}
.parcelList .head{padding: 12px 0;border-top: 1px solid #ddd;border-bottom: 1px solid #ddd;}
.parcelList .tinfo{padding: 8px 0;}
.parcelList .imgbox{width: 104px;height: 104px;padding: 3px;border-radius: 2px;margin: 0 auto;}
.parcelList .imgbox img{display: block;width: 100%;}


.searchImgBox .wrapbox{position: relative;width: 70px;height: 70px;}
.searchImgBox .wrapbox .pic{display: block;width: 100%;height: 100%;border: 2px solid #11ba66;box-sizing: border-box;}
.searchImgBox .wrapbox .gou2{display: block;width: 24px;height: 24px;position: absolute;top: 0;right: 0;}





@media screen and (max-width: 1280px) {
  .w1200{width: 900px;}
  .pubh1{font-size: 30px;}


  .navBar .searchBox input{width: 200px;}

  .searchBanner{height: 380px;}
  .searchBanner h1{font-size: 40px;padding: 60px 0 30px;}
  .searchBanner .searchBox{width: 800px;height: 60px;}
  .searchBanner .searchBox .ant-select{width: 120px;}
  .searchBanner .searchBox .ant-select-selector{font-size: 16px;}
  .searchBanner .searchBox .ant-select-arrow{font-size: 14px;}
  .searchBanner .searchBox .ant-input{font-size: 22px;}
  .searchBanner .searchBox .upbtn{width: 40px;height: 40px;}
  .searchBanner .searchBox .icon-tupian{font-size: 24px;}
  .searchBanner .searchBox .ant-btn{height: 60px;font-size: 20px;width: 120px;}

  .searchBanner .searchDesc{width: 90%;}
  .searchBanner .searchDesc::after{width: 66%;left: 17%;}
  .searchBanner .searchDesc .item{width: 33.33%;}

  .banners>div{width: 870px !important;}

  .serviceBox h1{font-size: 30px;text-align: center;font-weight: 500;padding: 20px 0;}
  .serviceBox .list .icon-box{width: 60px;}

  .noticeBox h1{font-size: 30px;padding-top: 10px;}

  .tjbox .list .item{width: 205px;}
  .tjbox .list .item .pic{width: 205px;height: auto;object-fit: contain;max-height: 100%;}

  .copyright{display: block;}
  .copyright .right{margin-left: -10px;}
  .footer .bot_menu .bt_item{width: 120px;}

  .help_top{padding-top: 40px;}
  .help_top h1,.help_top .ant-tabs-tab{font-size: 20px;}
  .help_search{padding: 30px 0 60px;}
  .help_search .inptBox,.help_search .ant-btn{height: 48px;}
  .help_search .inptBox .ant-input{font-size: 20px;}
  .help_search .ant-btn img{width: 28px;}
  .quesBox .left h1{font-size: 24px;}

  .ntlist .rlist .row .flexCenter p{font-size: 12px;}

  .detailbox{padding: 20px 20px 60px;width: 100%;}
  .detailbox .top{padding: 20px;}
  .detailbox .top h1{font-size: 20px;}
  .detailbox .top span{line-height: 36px;}
  .detailbox .cont{margin-top: 30px;padding: 4vw;}
  
  .shopdetail .filterbox .inptname{max-width: 15%;}
}

@media screen and (max-width: 985px) {
  .w1200{width: 720px;}
  .pubh1{font-size: 28px;}

  .navBar .logo{width: 90px;margin-right: 10px;}

  .navBar .searchBox .ant-select{width: 80px !important;}
  .navBar .searchBox input{width: 100px;}
  .navBar .icon-yunfeigusuan{font-size: 28px;}
  .navBar .avatar{width: 30px;height: 30px;}

  .searchBanner{height: 320px;}
  .searchBanner h1{font-size: 30px;padding: 50px 0 30px;}
  .searchBanner .searchBox{width: 600px;height: 40px;}
  .searchBanner .searchBox .ant-select{width: 100px;}
  .searchBanner .searchBox .ant-select-selector{font-size: 14px;}
  .searchBanner .searchBox .ant-select-arrow{font-size: 12px;}
  .searchBanner .searchBox .ant-input{font-size: 16px;}
  .searchBanner .searchBox .upbtn{width: 28px;height: 28px;border-radius: 4px;}
  .searchBanner .searchBox .icon-tupian{font-size: 20px;}
  .searchBanner .searchBox .ant-btn{height: 40px;font-size: 16px;width: 90px;}

  /* .searchBanner .searchDesc{width: 500px;}
  .searchBanner .searchDesc::after{width: 420px;} */

  .searchBanner .searchDesc{width: 90%;}
  .searchBanner .searchDesc::after{width: 66%;left: 17%;}
  .searchBanner .searchDesc .item{width: 33.33%;}


  .banners>div{width: 690px !important;}

  .iconsBox .iconfont{font-size: 24px;margin-right: 15px;}
  .iconsBox .title{font-size: 15px;}
  .iconsBox .flex{padding-right: 10px;}
  .serviceBox h1{font-size: 24px;text-align: center;font-weight: 500;padding: 10px 0;}
  .serviceBox .list .icon-box{width: 50px;}


  .noticeBox h1{font-size: 24px;padding-top: 0;}

  .tjbox .list .item{width: 165px;margin-right: 15px;}
  .tjbox .list .item .pic{width: 165px;height: auto;object-fit: contain;max-height: 100%;}

  .help_search{padding: 30px 0 40px;}
  .help_search .inptBox,.help_search .ant-btn{height: 40px;}
  .help_search .inptBox .ant-input{font-size: 16px;}
  .help_search .ant-btn img{width: 24px;}
  .quesBox .left h1{font-size: 20px;}

  .customBox .ant-form>.flex{box-shadow: 0 0 15px rgba(0,0,0,.2);flex-wrap: wrap;margin-bottom: 20px;padding: 20px 20px 0;position: relative;}
  .customBox .ant-form>.flex>div{width: 30% !important;}
  .customBox .ant-form>.flex>div.rowFlex{width: 40% !important;}
  .customBox .ant-form>.flex>div.btn{width: 15% !important;}
  .customBox .icon-dingdanhuishouzhan{position: absolute;margin: 0;right: 20px;top: 15px;}

  .ntlist>.flex{display: block;}
  .ntlist .list{width: 100%;}
  .ntlist .tjlist{margin-left: 0;}

  /* .detailbox{padding: 20px 20px 60px;width: 100%;}
  .detailbox .top{padding: 20px;}
  .detailbox .top h1{font-size: 20px;}
  .detailbox .top span{line-height: 36px;}
  .detailbox .cont{margin-top: 5.4vw;padding: 4vw;} */

  .goodsDetail .pinfo{display: block;}

  .goodsDetail .right{padding-left: 0;}
  .goodsDetail .pribox h1{font-size: 24px;}
  .goodsDetail .shopbtn .ant-btn{height: 48px;font-size: 16px;}

  .shopdetail .filterbox .inptpri{width: 80px;max-width: none;}
  .shopdetail .list .item{width: calc((100% - 60px) / 4);}
  .shopdetail .list .item:nth-child(4n){margin-right: 0;}
  .shopdetail .list .item:nth-child(5n){margin-right: 20px;}

}

@media screen and (max-width: 767px) {
  .w1200{width: 100%;}
  .pubh1{font-size: 18px;}

  /* .checkorderbox{width: fit-content;} */
  .checkorderbox .w1200 .goods{width: 800px;}
  .checkorderbox .conbox .flexCenter:last-child{flex-wrap: wrap;}
  .checkorderbox .all{padding: 0;font-size: 14px;}
  .checkorderbox .all span{margin-right: 20px;}
  .checkorderbox .all .pri{margin-right: 0;font-size: 24px;}

  .loginbox .bg{width: 100%;height: 560px;}
  .loginbox .box{width: 90%;height: auto;right: 5%;}
  .login_footer{margin-top: 30px;}
  .loginbox .ant-divider{margin-top: 50px;}

  .regbox .bg{height: 690px;}
  .regbox .box{height: auto;}

  .small_nav{padding: 0 4vw;}
  .small_nav p{font-size: 12px;}
  .small_nav .content_left p{width: 126px;}
  .small_nav .content_right{flex: 1;overflow: hidden;justify-content: flex-end;}
  .small_nav .content_right p{margin-right: 6px;}
  .small_nav .content_right .lang{font-size: 12px;}

  .navBar>.flexCenter{flex-wrap: wrap;padding-right: 4vw;}
  .navBar .logo{width: 70px;}
  .navBar .ant-menu{margin-left: auto;width: 280px;margin-right: -4vw;}
  .navBar .searchBox{margin-left: 4vw !important;flex: 1;overflow: hidden;}
  .navBar .searchBox input{flex: 1;width: auto;}
  .navBar .yfgs{margin-bottom: 6px;}
  .navBar .rbox{flex: none;width: 100%;margin-top: 4vw;margin-bottom: 4vw;}
  .navBar .ant-menu{min-width: auto !important;}

  .searchBanner{height: 240px;}
  .searchBanner .searchBox{width: 90%;height: 32px;}
  .searchBanner h1{font-size: 18px;padding: 20px 0 10px;}
  .searchBanner .searchBox .ant-select{width: 90px;}
  .searchBanner .searchBox .ant-select-selector{font-size: 14px;}
  .searchBanner .searchBox .ant-select-arrow{font-size: 12px;}
  .searchBanner .searchBox .ant-input{font-size: 14px;}
  .searchBanner .searchBox .upbtn{width: 24px;height: 24px;border-radius: 4px;}
  .searchBanner .searchBox .icon-tupian{font-size: 18px;}
  .searchBanner .searchBox .ant-btn{height: 32px;font-size: 14px;width: 70px;}

  .searchBanner .searchDesc{width: 90%;}
  .searchBanner .searchDesc::after{width: 66%;left: 17%;}
  .searchBanner .searchDesc .item{width: 33.33%;}

  .banners{margin-top: 4vw;margin-bottom: 3vw;}
  .banners.w1200,.banners .w1200{width: 90vw;}
  .banners>div{width: calc(90vw - 30px) !important;}
  .banners .jt{width: 24px;margin-top: -12px;}
  .banners .next{right: 15px;}

  .serviceBox h1{font-size: 18px;text-align: center;font-weight: 500;padding: 0 0 4vw 0;}
  .serviceBox .list{flex-wrap: wrap;}
  .serviceBox .list .icon-box{width: 45px;}
  .serviceBox .list .item{width: 48%;flex: auto;margin-right: 4%;box-shadow: 0 15px 40px 0 rgba(0,0,0,.1);margin-bottom: 20px;}
  .serviceBox .list .item:nth-child(2n){margin-right: 0;}
  /* .serviceBox .list .title{font-size: 1;} */

  .noticeBox h1{font-size: 18px;text-align: center;font-weight: 500;padding: 0;}
  .noticeBox .flexAllCenter{margin-bottom: 4vw;}
  .noticeBox{padding: 0 4vw;}
  .noticeBox .list{display: block;}
  .noticeBox .list .item{margin-bottom: 4vw;}


  .tjbox{padding-top: 4vw;}
  .tjbox h1{padding-bottom: 18px;}
  .tjbox .list{padding: 0 4vw 8vw;}
  .tjbox .list .item{width: 48%;margin-right: 4%;box-shadow: 0 0 15px rgba(0,0,0,.2);}
  .tjbox .list .item:nth-child(2n){margin-right: 0;}
  .tjbox .list .item .pic{width: 100%;}

  .iconsBox{flex-wrap: wrap;padding: 0 4vw;}
  .iconsBox .flex{flex: auto;width: 48%;margin-right: 4%;padding: 4vw 10px 4vw 0;}
  .iconsBox .flex:nth-child(2n){margin-right: 0;}
  .footer .help{display: block;}
  .footer .bot_menu{flex-wrap: wrap;width: 100%;padding: 0 4vw;}
  .footer .bot_menu .bt_item{width: 32%;margin-right: 2%;margin-bottom: 4vw;}
  .footer .bot_menu .bt_item:nth-child(3n){margin-right: 0;}
  .footer .rightcontent{padding-left: 4vw;}
  .footer .copyright,.footer .p1{padding-left: 4vw;padding-right: 4vw;font-size: 12px;}
  .footer .copyright .left{display: block;}
  .footer .footer_pay{width: 92vw;height: auto;margin-left: 4vw;margin-top: 4vw;}
  .footer .copyright .right p{font-size: 11px;margin-right: 4px;margin-left: 0;}
  .footer .copyright .right{margin-left: 0;margin-bottom: 6px;margin-top: 4px;}

  .help_top{padding: 4vw 4vw 0;}
  .help_top h1{font-size: 18px;}
  .help_top .ant-tabs-tab{font-size: 18px;}
  .help_search{padding: 4vw 4vw 8vw;}
  .help_search .inptBox{width: 100%;height: 42px;}
  .help_search .inptBox .ant-input{font-size: 16px;height: 42px;}
  .help_search .ant-btn{height: 42px;width: 80px;}
  .help_search .ant-btn img{width: 24px;}
  .helpBox{padding: 4vw;}
  .helpBox h1{font-size: 20px;line-height: 30px;}
  .helpBox .list .item p{line-height: 30px;}


  .help_top h1, .help_top .ant-tabs-tab{font-size: 16px;}
  .quesBox{padding: 5vw 0;}
  .quesBox .left h1{font-size: 16px;}
  .quesBox .left{width: 40%;}
  .quesBox .left .ant-menu .ant-menu-submenu-title{padding-left: 10px !important;}
  .quesBox .left .ant-menu .ant-menu-sub.ant-menu-inline .ant-menu-item{padding-left: 20px !important;}
  .quesBox .right{flex: 1;overflow: hidden;padding-left: 0;padding-right: 4vw;}
  .quesBox .list{padding: 4vw 0 4vw 2vw;}

  .yfgs_banner .detail{font-size: 12px;}
  .yfgs_banner .ant-form>.flex{display: block;}
  .yfgs_banner .ant-form .ant-form-item.item49{width: 100%;margin-right: 0;margin-bottom: 4vw;}
  .yfgs_banner .ant-form-item .ant-form-item-label{flex: none !important;max-width: none !important;padding-bottom: 0;}
  .yfgs_banner .item49.flex{width: 100%;margin-right: 0;}
  .yfgs_banner h1{padding-top: 5vw;}
  .yfgs_banner .ant-form{padding: 0 4vw;}
  .yfgs_banner .ant-form .ant-btn{height: 36px;font-size: 16px;display: block;margin: 0 auto;}
  .yfgs_banner .ant-form-item .ant-form-item-control{flex: 1 !important;max-width: none !important;}

  .customBox{padding: 0 4vw;position: relative;z-index: 1;}
  .customBox .ant-form>.flex>div,.customBox .ant-form>.flex>div.rowFlex,.customBox .ant-form>.flex>div.btn{width: 100% !important;margin-right: 0;}
  .customBox .flexAllCenter .ant-btn{height: 36px;font-size: 15px;}

  /* .addrbox .flex .ant-form-item{width: 100%;margin-right: 0;} */
  .addrbox .ant-form{margin-top: 3.2vw;}
  .addrbox .item49:last-child .ant-form-item-label,.addrbox .item32:nth-child(2) .ant-form-item-label,.addrbox .item32:nth-child(3) .ant-form-item-label{opacity: 0;}
  .addrbox .ant-form-item{margin-bottom: 3vw;}
  .addrbox .ant-form-item-label{padding-bottom: 0;}
  .addrbox .ant-form-item-explain-error{font-size: 12px;}
  .addrbox .ant-form-item:nth-child(6) .ant-form-item-control,.addrbox .ant-form-item:nth-child(7) .ant-form-item-control{flex: 1 !important;}

  .nt_tabs{padding: 0 4vw;}
  .nt_tabs .ant-tabs .ant-tabs-tab{font-size: 15px;}
  .nt_tabs .ant-input{display: none;}
  .ntlist{padding: 4vw;}
  .ntlist .item .pic{max-height: 85px;}
  .ntlist .right h1{font-size: 14px;}
  .ntlist .right{padding: 0 3.2vw;}
  .ntlist .item{margin-bottom: 4vw;}
  .ntlist .right .desc{-webkit-line-clamp: 2;font-size: 12px;margin-top: 4px;}
  .ntlist .right .flexCenter p{font-size: 12px;margin-top: 3px;}
  .ntlist .right .user{line-height: 16px;}

  .detailbox{padding: 4vw 4vw 10vw;width: 100%;}
  .detailbox .top{padding: 4vw;}
  .detailbox .top h1{font-size: 16px;}
  .detailbox .top span{line-height: 30px;}
  .detailbox .cont{margin-top: 5.4vw;padding: 4vw;}

  .goodsDetail .pinfo{padding: 4vw;}
  .goodsDetail .pinfo .left{width: 100%;}
  .goodsDetail .pinfo .pic_box img{width: 100%;}
  .goodsDetail .pribox h1{font-size: 20px;}
  .goodsDetail .atips{display: block;}
  .goodsDetail .specbox .desc.borderb1{margin-top: 15px;}
  .goodsDetail .sjtabs,.goodsDetail .shopCont,.goodsDetail .infotabs,.goodsDetail .details{padding-left: 4vw;padding-right: 4vw;}
  .goodsDetail .shopCont .next_btn, .goodsDetail .shopCont .prev_btn{top: 30%;}
  .goodsDetail .shopCont .prev_btn{left: 5vw;}
  .goodsDetail .reviews{padding: 0 4vw;}
  .goodsDetail .reviews .lcont{width: 35%;}
  .goodsDetail .reviews .item{padding-bottom: 4vw;margin-bottom: 4vw;}
  .goodsDetail .noticebox{padding-left: 4vw;padding-right: 4vw;}
  .goodsDetail .noticebox img{margin-right: 4vw;width: 16vw;height: 16vw;}

  .goodsDetail .express .step{padding: 0 2.4vw;font-size: 13px;}
  .goodsDetail .details>div{max-width: 100%;}

  .shopdetail{padding: 4vw 0;}
  .shopdetail .list{padding: 0 4vw;}
  .shopdetail .list .item{width: calc((100% - 40px) / 3);margin-right: 20px !important;box-shadow: 0 0 15px rgba(0,0,0,.2);}
  .shopdetail .list .item:nth-child(3n){margin-right: 0 !important;}
  .shopdetail .shopinfo .logo{width: 50px;height: 50px;}
  .shopdetail .shopinfo .btn span{display: none;}
  .shopdetail .shopinfo .btn{padding: 0 10px;}
  .shopdetail .catebox{padding: 0 4vw;}
  .shopdetail .catebox .name,.shopdetail .catebox .list p{line-height: 32px;height: 32px;}
  .shopdetail .catebox .list{height: 32px;}
  .shopdetail .catebox .more{line-height: 32px;}
  .shopdetail .catebox .name,.shopdetail .catebox .more{width: 15%;}
  .shopdetail .catebox .list{margin-right: -4vw;}
  .shopdetail .catebox .list p{margin-right: 4vw;font-size: 12px;}
  .shopdetail .filterbox{padding: 0 4vw;}
  .shopdetail .filterbox .sortbtn{margin-right: 20px;margin-bottom: 10px;}
  .shopdetail .filterbox .inptname{margin-left: 0;max-width: none;margin-bottom: 10px;}
  .shopdetail .filterbox .inptpri{margin-left: 10px;}
  .shopdetail .list .item .pri{font-size: 14px;}

  .transportBox .titbox{padding: 4vw;}
  .transportBox .titbox p{font-size: 18px;margin-left: -10px;}
  .transportBox .t1{padding: 0 4vw;}
  .transportBox .send_addr{margin: 10px 4vw 0;padding: 4vw;}
  .transportBox .send_addr .p1{margin-left: 5.4vw;}
  .transportBox .send_addr .label{margin-left: 5.4vw;width: 90px;}
  .transportBox .tip{margin: 10px 4vw 0;}
  .transForm{margin-left: 4vw;margin-right: 4vw;}
  .transportBox .t2{padding-left: 4vw;padding-right: 4vw;}
  .transForm .add{height: 40px;margin-top: 4vw;}
  .transForm .add .iconfont{font-size: 18px;}
  .transForm .flexAllCenter .ant-btn{height: 48px;max-width: 40%;}

  .uniorderbox{padding: 4vw 0 8vw;}
  .uniorderbox .ant-form{padding: 0 4vw;}
  .uniorderbox .ant-form .item32{width: 100%;margin-right: 0;}
  .uniorderbox .foot{display: block;}
  .uniorderbox .ant-form .left{margin-right: 0;}
  .uniorderbox .foot>.ant-btn{max-width: 40%;height: 54px;font-size: 16px;margin-top: 4vw;}
  .uniorderbox .ant-form .ant-form-item{margin-bottom: 10px;}
  .uniorderbox .h1{padding: 0 4vw;font-size: 20px;margin-top: 5vw;}
  .uniorderbox .tit{font-size: 12px;padding-left: 4vw;padding-right: 4vw;}
  .uniorderbox .tit i{margin: 0 2vw;}
  .uniorderbox .ClsCost{font-size: 20px;padding: 4vw 0;margin-bottom: 0;}
  .uniorderbox .totalpri{position: initial;}
  .uniorderbox .totalpri p{text-align: left;}

  .dzbox .notbox h1{font-size: 20px;}
  .dzbox .hx{margin-top: 56px;margin-left: 10px;margin-right: 10px;}
  .dzbox .ant-form-item-label{margin-top: -8px;}
  .dzbox .ts{padding-left: 0;}

  .userbox{padding: 2vw 0 8vw;}
  .userbox .tips{margin-top: 4vw;padding: 0 4vw;}
  .userbox .cen{margin-top: 4vw;}
  .userhome .info{display: block;position: relative;padding: 4vw;}
  .userhome .info .lbox{width: 100%;}
  .userhome .info .lbox .avatarbox{max-width: 100%;}
  .userhome .info .lbox .avatarbox{width: 60px;height: 60px;}
  /* .userhome .info .rbox>div.item32{position: absolute;top: 4vw;right: 4vw;} */
  .userhome .info .rbox{flex-wrap: wrap;flex: none;}
  .userhome .info .rbox .item32{width: 100%;margin-right: 0;margin-top: 10px;}
  .userhome .info .rbox .rowFlex{width: 33%;flex: none;margin-top: 15px;}
  .userhome .info .rbox .p1{font-size: 12px;}
  .userhome .info .rbox .p2{font-size: 16px;}
  .userhome .ant-table-wrapper{width: 980px;max-width: 980px;}

  .usermenu{width: 22% !important;}
  .usermenu.ant-menu-vertical .ant-menu-item{padding-inline: 10px;}

  .cart_search{flex-wrap: wrap;}
  .cart_search .ant-select{margin-bottom: 10px;}
  .cart_table{background-color: #fff;width: 980px;}
  /* .cart_table .child>.flex{display: flex;flex-wrap: wrap;}
  .cart_table .infos{width: 80%;}
  .cart_table .box .pic{width: 30px;height: 30px;}
  .cart_table .pribox, .cart_table .totalbox, .cart_table .czl{width: 50%;margin-top: 10px;}
  .cart_table .goods_num{width: 100%;margin-top: 10px;}
  .cart_table .bqbox{padding-left: 26px;flex-wrap: wrap;} */

  .fixbot>.flexCenter{flex-wrap: wrap;}
  .fixbot.flexCenter{flex-wrap: wrap;}
  .fixbot .ant-pagination{margin-top: 6px;width: 100%;}
  .fixbot .ant-pagination li{height: 24px;line-height: 22px;min-width: 24px;}
  .fixbot .bot .ant-btn{width: 45%;height: 36px;padding: 4px 0;max-width: 120px;}
  .fixbot .total_pri .pri{font-size: 18px;}

  .sc_searchbox{flex-wrap: wrap;}
  .sc_searchbox .ant-select{margin-bottom: 10px;}
  .goodsItem{width: 47%;}
  .shopItem{width: 99%;}
  .shopItem .samebox{display: block;}
  .shopItem .sameBox>.marginl20{margin-top: 4px;}

  .top_search_order .ant-input-affix-wrapper{width: 100% !important;}
  
  .userbox .content{overflow-x: auto;}
  .order_table,.parcelList,.money_table,.jftable,.dztable{min-width: 980px;overflow-x: auto;}

  /* .userbox .content{min-width: 1020px;background-color: #fff;} */

  .edit_size_box{display: block !important;}
  .edit_size_box .rbox{margin-left: 0;margin-top: 20px;}

  .fgwrap{margin-top: 10px !important;}
  .fgbox h1{font-size: 18px;line-height: 52px;}
  .fgbox .bgbai h2{line-height: 80px;}
  .fgbox .box{width: 90%;}
  .fgbox .box>.flex{align-items: flex-end;}
  .fgbox .box .ver_code{margin-bottom: 24px;}
  .fgbox .box .ant-btn{width: 100%;margin-top: 30px;}

  .login_footer span:first-child{display: block;margin-right: 0;width: 100%;}

  #asdf .fixbot .bot>.rowFlex{flex: none;width: 100%;}
  #asdf>div{width: auto !important;}

  .moneybox .top>.flexCenter,.moneybox .bot{flex-wrap: wrap;}
  .moneybox .top .pri{font-size: 20px;}
  .moneybox .top .ant-btn{margin-left: 0 !important;width: 36% !important;height: 32px !important;}
  .coupon_nav p{padding: 0;font-size: 12px;min-width: 33.33%;}
  .couponlist .item{width: 98%;margin-right: 2%;}
  .couponlist .item .pri{font-size: 24px;}
  .intgl_top{flex-wrap: wrap;}
  .intgl_top .rowFlex{flex: none;}
  .intgl_top .left span{font-size: 24px;}

  .info_cont .flwp>div{width: 98%;}

  .levelbox{width: 980px;overflow-x: auto;}

  .secInfo .htips{margin-left: 0;}

  .zjboxxx{flex-wrap: wrap;}

  .editformbox{width: 100% !important;}

  .promyBox .flex,.promyBox .flexCenter{flex-wrap: wrap;}
  .promyBox .rowFlex,.qrcodebox .item{flex: none;width: 100%;}
  .commission_box .head{height: auto;}
  .commission_box .head h1{font-size: 18px;}
  .commission_box .head .left div>span{display: block;width: 100%;margin-right: 0;}
  .commission_box .yubox span{font-size: 20px;line-height: 28px;}
  .commission_box>.bgbai .ant-btn{margin-left: 0 !important;}
  .commission_box .list .item32{width: 98%;padding: 10px;}
  .qybox .list{flex-wrap: wrap;}
  .qybox .list .item1{width: 100%;}
  .qybox .list .rowFlex{flex: none;width: 100%;border-top: 1px solid #d4d1d1;padding-top: 20px;}
  .promyBox .swiper .flexCenter{flex-wrap: initial;}
  .promyBox .poabs{position: initial;}
  .promyBox .ant-carousel{min-width: 100%;}
  .promyBox .ant-carousel h4.rowFlex{flex: 1;text-overflow: ellipsis;white-space: nowrap;}

  /* .package_list .desc{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;} */
  .package_list .item .time_box .h1 span{font-size: 16px;}
  .package_list .item .fee_box h1{font-size: 16px;}
  .package_list .item .logo{width: 90%;}
  .package_list .item .logo img{width: 100%;height: auto;}
  .package_list .item .time_box .ant-progress{width: 100%;}
  .package_list .item .time_box .ant-progress div.ant-progress-outer{padding-right: 0;}
  .package_list .item .time_box .ant-progress-text{display: block;}
  .package_list .btn .ant-btn{width: 100%;font-size: 12px;padding: 0;}
  .package_list .item .details>.flex{flex-wrap: wrap;}
  .package_list .item .details .left{width: 100%;}
  .package_list .item .details .left>.flex{flex-wrap: wrap;}
  .package_list .item .details .left>.flex .marginl10{margin-left: 0;margin-top: 10px;}
  .package_list .item .details .left>.flex .ant-picker{margin-right: 10px;}
  .package_list .item .details .chart_box{padding-bottom: 50px;}
  .package_list .item .details .right{flex: none;width: 100%;margin: 15px 0 0;}
  .package_list .jf_table>.flex>div{height: auto;}
  .package_list .item .details{padding: 20px 4vw 10px;}

  .wbbzlist .row15{width: 100%;}
  .wlqdwrap{overflow-x: auto;}
  .wlqdlist{width: 680px;background-color: #fff;}
  .line_content .ant-progress .ant-progress-outer{padding-right: 0;}
  .line_content .ant-progress-text{display: block;}
  .line_content .ant-btn{padding: 0;width: 100%;font-size: 12px;}

  .ant-picker-panels{display: block !important;flex-wrap: wrap !important;}
  .ant-picker-panel{display: block !important;}
  .ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner{height: 20px;line-height: 20px;min-width: 20px;font-size: 12px;}
  .ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th{width: 20px;height: 20px;font-size: 12px;}
  .ant-picker-dropdown .ant-picker-cell{padding: 2px 0;}

  .editbox .ant-form-item.item49,.editbox .ant-form-item.flex1{align-self: end;}

  .contrybox{width: 84vw;}

  .userhome .info .lbox .avatarbox span{font-size: 10px;}
  .ordergodsbox{width: 100%;overflow-x: auto;}
  .countrylist{width: 100%;}
  .countrylist .item{width: 50%;}
  .secInfo .lw{width: auto;display: block;}
  .secInfo .ant-progress{flex: 1;}
  .secInfo>.flexCenter{align-items: baseline;}

  .aboutbox{margin-top: 8vw;}
  .aboutbox .left{width: auto;margin-right: 4vw;display: none;}
  .aboutbox .left div{margin: 6vw 4vw;}
  .aboutbox .left div.active::before{left: -4vw;}



}


@media screen and (max-width: 375px) {
  .small_nav .lang .icon-a-bianzu4,.small_nav .lang .icon-yuyan{display: none;}
}

.editQd{
  margin-left: 15px;
  color: red;
  cursor: pointer;
}
.qdListItem{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.qdListItem:hover{
  background: #e4fcf0;
}
.qdRadio{
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border:1px solid #11ba66;
  margin-left: 10px;
  margin-right: 5px;
}
.qdInfo{
  flex:1;
}
.qdTitle{
  font-size: 14px;

}
.qdDesc{
  font-size: 12px;
  color: #8b8b8b;
}
.qdActive{
  background: #11ba66;
}
.borderr{
  border-right: 1px solid #d4d1d1;
}
.cursor{
  cursor: pointer;
}

.msgInput{
  padding: 10px;
}
.couponBtn{
  width: 100%;
  background: #11ba66;
  color: #fff;
}
.choosePayment{
  margin-left: 30px;
  margin-top: 10px;
  font-size: 18px;
}
.flexwrap{
  flex-wrap: wrap;
}
.flexprice{
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
}
.AgreementsPage{
  padding: 15px;
  min-height: 100vh;
  box-sizing: border-box;
  max-width: 100vw;
  word-break: break-all;
}
.homepage .infinite-scroll-component{
  padding: 0 !important;
}
#root{
  max-width: 100vw;
}
.spacebetween{
  justify-content: space-between;
}